import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getCategory(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CATEGORY}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getCategories(dataTableParams?: DataTableParamsModel) {
    

  const baseUrl = `${c.API_ENDPOINTS.CATEGORY}`;

  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getCategoriesByShortcodes(groupShortcode: string, clientShortcode: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CATEGORIES_SHORTCODE}/${groupShortcode}/${clientShortcode}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postCategory(parentId: string, groupId: string, clientId: string, order: number, title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.CATEGORY,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        parentId,
        groupId,
        clientId,
        order,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putCategory(
  id: string,
  parentId: string,
  groupId: string,
  clientId: string,
  order: number,
  title: string,
  description: string | null,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CATEGORY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        parentId,
        groupId,
        clientId,
        order,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveCategory(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CATEGORY}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

