import * as React from 'react';
import './Stepper.scss';
import TickMark from '../../assets/images/svgs/tick_mark.svg';

type Props = {
  steps: string[],
  activeStep?: number,
  onStepClick?: (index: number) => void,
  hideMobileLabels?: boolean,
};

const Stepper = (props: Props) => {
  const {  activeStep = 0,  steps = [], onStepClick = () => {}, hideMobileLabels = false } = props;

  const goToStep = (index:number) =>{
    onStepClick(index);
  };

  const getCircleClassName = (index: number) => {
    if (index === activeStep) {
      return 'stepper-container__circle stepper-container__circle--active';
    } else if (index < activeStep) {
      return 'stepper-container__circle stepper-container__circle--success';
    } else {
      return 'stepper-container__circle';
    }
  };

  const getTextClassName = (index: number) => {
    if (index < activeStep) {
      return 'stepper-container__text stepper-container__text--success';
    } else {
      return 'stepper-container__text';
    }
  };

  return (
      <>
        <div className="stepper-container">
          {(steps.length >= 2) &&  steps.map((text, index) => (
              <React.Fragment key={index}>
                <div onClick={() => goToStep(index)} className={getCircleClassName(index)}>
                  { index < activeStep && <img className="stepper-container__checkmark" src={TickMark} alt='checkmark'/> }
                  <div className={getTextClassName(index)}>{text}</div>
                </div>
                {(steps.length !== index + 1) && <div className="stepper-container__line"></div>}
              </React.Fragment>
          ))}
        </div>
        {!hideMobileLabels && <div className="stepper-container__mobile-text">{steps[activeStep]}</div>}
      </>
  );
};
export default Stepper;
