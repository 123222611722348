import axios from 'axios';
import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';


export function getPopularSearch(id: string) {
  return axios({
    url: `${c.API_ENDPOINTS.POPULAR_SEARCH}/${id}`,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}



export function getPopularSearches(
  dataTableParams?: DataTableParamsModel,
  groupShortcode?: string,
  clientShortcode?: string,
) {
  const baseUrl = `${c.API_ENDPOINTS.POPULAR_SEARCH}`;

  const url = groupShortcode && clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  return axios({
    url,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    params: {
      dataTableParams,
      groupShortcode,
      clientShortcode,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function getPopularSearchesByType(
  searchType: string,
  dataTableParams?: DataTableParamsModel,
) {
  const url = dataTableParams?.fetchParams?.groupShortcode && dataTableParams?.fetchParams?.clientShortcode
    ? `${c.API_ENDPOINTS.POPULAR_SEARCH}-shortcode/by/type`
    : `${c.API_ENDPOINTS.POPULAR_SEARCH}/by/type`;

  return axios({
    url,
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
    params: {
      searchType,
      dataTableParams,
    },
  })
    .then((response) => response?.data)
    .catch((error) => error.response?.data);
}

export function putPopularSearch(
  searchType: string,
  searchTerm: string,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.POPULAR_SEARCH}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        searchType,
        searchTerm,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deletePopularSearch(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.POPULAR_SEARCH}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


