import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../../actions/Auth';
//import Modal from '../../../components/Modal';


import {
  isPostingUser,
  postingUserError,
  postingUserFailed,
  postingUserValidationErrors,
  isFetchingUsers,
} from '../../../selectors/User';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import HeadlessView from '../../../components/HeadlessView';


const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  //const [toggle, setToggle] = useState(false);
  //const [confirmed, setConfirmed] = useState(false);
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);

  const url = window.location.pathname;

  const activeId = url.split('/').pop();


  const formConfig = [
    { field: 'password', validators: [isRequired] },
    { field: 'confirmPassword', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  useEffect(() => {
  }, [dispatch]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.USER_LOGIN);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      // @ts-ignore
      dispatch(resetPassword(activeId, password));
    }
  };


  return <HeadlessView title="Reset Password">
                    {postingError && (
                        <Alert id="errorAlert" className="mt-3" variant="danger">
                            {postingError}
                        </Alert>
                    )}
                    <form>
                        <Input v={v} err={err}
                               name="password"
                               id="password"
                               label="Password"
                               required
                               type="password"
                               value={password}
                               onChange={setPassword}
                               disabled={isPosting || isFetching}
                               onBlur={setPassword}
                               placeholder=""
                        />
                        <Input v={v} err={err}
                               name="confirmPassword"
                               id="confirmPassword"
                               label="Confirm Password"
                               required
                               type="password"
                               value={confirmPassword}
                               onChange={setConfirmPassword}
                               disabled={isPosting || isFetching}
                               onBlur={setConfirmPassword}
                               placeholder=""
                        />
                        <div className="row">
                            <div className="col-md-4">
                                <Button disabled={!isFormValid() || password !== confirmPassword} isLoading={isPosting || isFetching}
                                        onClick={onSubmit}
                                        title={'RESET'}/>
                            </div>
                        </div>
                    </form>
    </HeadlessView>;
};

export default UserForm;



