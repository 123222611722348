// const API_BASE_URL = 'https://dev-api.excessmaterialsexchange.com/api';
const API_BASE_URL = 'https://api.excessmaterialsexchange.com/api';
// const API_BASE_URL = 'https://eme.mrhighline.com/api';
// const API_BASE_URL = 'http://localhost:8000/api';


const API_ENDPOINTS = {
  FIELD_TYPE: `${API_BASE_URL}/field-type`,
  FIELD_TYPE_OPTION: `${API_BASE_URL}/field-type-option`,
  FIELD: `${API_BASE_URL}/field`,
  FIELD_PROPERTY: `${API_BASE_URL}/field-property`,
  FIELD_PROPERTY_BY_ENTRY: `${API_BASE_URL}/field-property/entry`,
  ROLE: `${API_BASE_URL}/role`,
  USER: `${API_BASE_URL}/user`,
  GROUP: `${API_BASE_URL}/group`,
  CLIENT: `${API_BASE_URL}/client`,
  ASSET: `${API_BASE_URL}/asset`,
  CATEGORY: `${API_BASE_URL}/category`,
  CATEGORY_PUBLIC: `${API_BASE_URL}/category-public`,
  CATEGORIES_SHORTCODE: `${API_BASE_URL}/categories`,
  ENTRY: `${API_BASE_URL}/entry`,
  ENTRY_PUBLIC: `${API_BASE_URL}/entry-public`,
  ENTRY_REUSED_ITEMS: `${API_BASE_URL}/entry-reused`,
  MATCH_ENTRY: `${API_BASE_URL}/matchmake`,
  ENTRY_MATCHES: `${API_BASE_URL}/entry-matches`,
  ENTRY_STATUS: `${API_BASE_URL}/entry-status`,
  ENTRIES_SHORTCODE: `${API_BASE_URL}/entries`,
  ENTRY_WITH_FIELDS: `${API_BASE_URL}/entry-with-fields`,
  ENTRY_FIELD: `${API_BASE_URL}/entry-field`,
  ENTRY_FIELD_PUBLIC: `${API_BASE_URL}/entry-field-public`,
  FORM: `${API_BASE_URL}/form`,
  FORM_FIELD: `${API_BASE_URL}/form-field`,
  FORM_FIELD_PUBLIC: `${API_BASE_URL}/form-field-public`,
  VIEW: `${API_BASE_URL}/view`,
  DUPLICATE_VIEW: `${API_BASE_URL}/duplicate-view`,
  TRANSFER_ENTRIES: `${API_BASE_URL}/transfer-entries`,
  ASSET_FILE: `${API_BASE_URL}/asset/file`,
  ROLE_GROUP: `${API_BASE_URL}/role-group`,
  ROLE_CLIENT: `${API_BASE_URL}/role-client`,
  VIEW_ROLE: `${API_BASE_URL}/view-role`,
  FORM_FIELD_ROLE: `${API_BASE_URL}/form-field-role`,
  ENTRY_EDGE: `${API_BASE_URL}/entry-edge`,
  ENTRY_EDGE_PUBLIC: `${API_BASE_URL}/entry-edge-public`,
  CONFIGURATION: `${API_BASE_URL}/configuration`,
  CONFIGURATION_ASSETS: `${API_BASE_URL}/configurationAssets`,
  ENTRY_HISTORY: `${API_BASE_URL}/entry-history`,
  ENTRY_HISTORY_PUBLIC: `${API_BASE_URL}/entry-history-public`,
  TIMELINE: `${API_BASE_URL}/entry-timeline`,
  TIMELINE_PUBLIC: `${API_BASE_URL}/entry-timeline-public`,
  CHILD_LOCATION_TIMELINE: `${API_BASE_URL}/entry-children-location-timeline`,
  PARENT_LOCATION_TIMELINE: `${API_BASE_URL}/entry-parents-location-timeline`,
  ENTRY_FIELD_HISTORY: `${API_BASE_URL}/entry-field-history`,
  ENTRY_FIELD_HISTORY_PUBLIC: `${API_BASE_URL}/entry-field-history-public`,
  TAG: `${API_BASE_URL}/tag`,
  TAGS: `${API_BASE_URL}/tags`,
  BADGE: `${API_BASE_URL}/badge`,
  SOLUTION: `${API_BASE_URL}/solution`,
  MATERIALSFUNCTION: `${API_BASE_URL}/function`,
  SOLUTION_BADGE: `${API_BASE_URL}/solution-badge`,
  SOLUTION_FUNCTION: `${API_BASE_URL}/solution-function`,
  SOLUTION_OUTPUT_TAG: `${API_BASE_URL}/solution-output-tag`,
  SOLUTION_INPUT_TAG: `${API_BASE_URL}/solution-input-tag`,
  ENTRY_TAG: `${API_BASE_URL}/entry-tag`,
  MATCH: `${API_BASE_URL}/match`,
  AI_WIZARD_TEXT: `${API_BASE_URL}/ai/text`,
  AI_WIZARD_IMAGE: `${API_BASE_URL}/ai/image`,
  AI_WIZARD_VALUATION: `${API_BASE_URL}/ai/valuation`,
  AI_WIZARD_XY_GENERATION: `${API_BASE_URL}/ai/generateXY`,
  SHADOW_ENTRY: `${API_BASE_URL}/shadow-entry`,
  MARKETPLACE: `${API_BASE_URL}/marketplace`,
  MARKETPLACES_PUBLIC: `${API_BASE_URL}/marketplaces-public`,
  MARKETPLACE_ENTRY: `${API_BASE_URL}/marketplace-entry`,
  ENTRIES_BY_MARKETPLACE: `${API_BASE_URL}/marketplace-entries`,
  CREATE_MARKETPLACE_ENTRIES_BATCH: `${API_BASE_URL}/marketplace-entries/batch`,
  AUCTION: `${API_BASE_URL}/auction`,
  AUCTIONS_PUBLIC: `${API_BASE_URL}/auctions-public`,
  AUCTION_BID: `${API_BASE_URL}/auction-bid`,
  AUCTION_BID_PLACE: `${API_BASE_URL}/auction-bid/place`,
  AUCTION_ENTRY: `${API_BASE_URL}/auction-entry`,
  AUCTION_BATCH_ENTRY: `${API_BASE_URL}/auction-entries/batch`,
  AUCTION_EDIT_BATCH_ENTRY: `${API_BASE_URL}/auction-entries/batch/edit`,
  AUCTION_ENTRIES_BY_AUCTION_ID:`${API_BASE_URL}/auction-entries-by-auction`,
  POPULAR_SEARCH: `${API_BASE_URL}/popular-search`,
  QR_REDIRECT: `${API_BASE_URL}/system/redirect/:groupShortcode/:clientShortcode/:entryId`,
  LEAD: `${API_BASE_URL}/lead`,

};


const APP_ROUTES = {
  CORE: window.location.origin,
  STORYBOARD: '/storyboard',
  DASHBOARD: '/',
  LANDING_PAGE: '/home',
  SEARCH_RESULT: '/search-query',
  STATIC_PAGE_VIEW: '/page/view/static/:pageKey',
  ERROR_404: '/*',
  VISION: '/vision',
  PROFILE: '/profile',
  MERIDIAN: '/meridian',
  PRODUCTS: '/products',
  PRODUCT: '/products/:productId',
  PROJECTS: '/projects',
  PROJECT: '/projects/:projectId',
  USER_LOGIN: '/user/login',
  USER_REGISTER: '/user/register',
  USER_PENDING_VERIFICATION: '/user/verification',
  FORGOT_PASSWORD: '/user/resetPassword',
  RESET_PASSWORD: '/user/resetPassword/:id',
  VIEW_RENDERER: '/view/:id',
  ENTRY_RENDERER: '/entry/:id',
  ENTRY_RENDERER_PUBLIC: '/entry-public/:id',
  FORM_RENDERER: '/form/:id',
  FORM_RENDERER_EDIT: '/form/edit/:id',
  USER_REGISTER_SHORTCODE: '/:groupShortcode/:clientShortcode/user/register',
  USER_LOGIN_SHORTCODE: '/:groupShortcode/:clientShortcode/user/login',
  DASHBOARD_SHORTCODE: '/:groupShortcode/:clientShortcode/',
  MERIDIAN_SHORTCODE: '/:groupShortcode/:clientShortcode/meridian',
  VIEW_RENDERER_SHORTCODE: '/:groupShortcode/:clientShortcode/view/:id',
  ENTRY_RENDERER_SHORTCODE: '/:groupShortcode/:clientShortcode/entry/:id',
  TIMELINE_ENTRY_RENDERER_SHORTCODE: '/:groupShortcode/:clientShortcode/timeline/entry/:id',

  CLIENT_PROFILE: '/client/:id/profile',
  CLIENT_PROFILE_SHORTCODE: '/:groupShortcode/:clientShortcode/client/:id/profile',

  //FORM_RENDERER_SHORTCODE: '/:groupShortcode/:clientShortcode/form/:id',

  //ADMIN AREA
  ROLE: '/admin/role',
  ROLE_CREATE: '/admin/role/create',
  ROLE_UPDATE: '/admin/role/update/:id',
  ROLE_GROUP: '/admin/role-group',
  ROLE_GROUP_CREATE: '/admin/role-group/create',
  ROLE_CLIENT: '/admin/role-client',
  ROLE_CLIENT_CREATE: '/admin/role-client/create',
  USER: '/admin/user',
  USER_CREATE: '/admin/user/create',
  USER_UPDATE: '/admin/user/update/:id',
  GROUP: '/admin/group',
  GROUP_CREATE: '/admin/group/create',
  GROUP_UPDATE: '/admin/group/update/:id',
  CLIENT: '/admin/client',
  CLIENT_CREATE: '/admin/client/create',
  CLIENT_UPDATE: '/admin/client/update/:id',
  ASSET: '/admin/asset',
  ASSET_CREATE: '/admin/asset/create',
  ASSET_UPDATE: '/admin/asset/update/:id',
  CATEGORY: '/admin/category',
  CATEGORY_CREATE: '/admin/category/create',
  CATEGORY_UPDATE: '/admin/category/update/:id',
  FIELD: '/admin/field',
  FIELD_CREATE: '/admin/field/create',
  FIELD_UPDATE: '/admin/field/update/:id',
  FIELD_TYPE: '/admin/field-type',
  FIELD_TYPE_CREATE: '/admin/field-type/create',
  FIELD_TYPE_UPDATE: '/admin/field-type/update/:id',
  FIELD_TYPE_OPTION: '/admin/field-type-option',
  FIELD_TYPE_OPTION_CREATE: '/admin/field-type-option/create',
  FIELD_TYPE_OPTION_UPDATE: '/admin/field-type-option/update/:id',
  FIELD_PROPERTY: '/admin/field-property',
  FIELD_PROPERTY_CREATE: '/admin/field-property/create',
  FIELD_PROPERTY_UPDATE: '/admin/field-property/update/:id',
  ENTRY: '/admin/entry',
  ENTRY_CREATE: '/admin/entry/create',
  ENTRY_UPDATE: '/admin/entry/update/:id',
  ENTRY_FIELD: '/admin/entry-field',
  ENTRY_FIELD_CREATE: '/admin/entry-field/create',
  ENTRY_FIELD_UPDATE: '/admin/entry-field/update/:id',
  FORM: '/admin/form',
  FORM_CREATE: '/admin/form/create',
  FORM_UPDATE: '/admin/form/update/:id',
  FORM_FIELD: '/admin/form-field',
  FORM_FIELD_CREATE: '/admin/form-field/create',
  FORM_FIELD_UPDATE: '/admin/form-field/update/:id',
  FORM_FIELD_ROLE: '/admin/form-field-role',
  FORM_FIELD_ROLE_CREATE: '/admin/form-field-role/create',
  VIEW: '/admin/view',
  VIEW_CREATE: '/admin/view/create',
  VIEW_UPDATE: '/admin/view/update/:id',
  VIEW_ROLE: '/admin/view-role',
  VIEW_ROLE_CREATE: '/admin/view-role/create',
  ENTRY_EDGE: '/admin/entry-edge',
  ENTRY_EDGE_CREATE: '/admin/entry-edge/create',
  ENTRY_EDGE_UPDATE: '/admin/entry-edge/update/:id',
  CONFIGURATION: '/admin/configuration',
  CONFIGURATION_CREATE: '/admin/configuration/create',
  CONFIGURATION_UPDATE: '/admin/configuration/update/:id',
  ENTRIES_HISTORY: '/admin/entry-history',
  ENTRY_HISTORY: '/admin/entry-history/:id',
  ENTRY_FIELDS_HISTORY: '/admin/entry-field-history',
  ENTRY_FIELD_HISTORY: '/admin/entry-field-history/:id',

  ADMIN_VERIFICATION:'/verify',

  SOLUTION: '/admin/solution',
  SOLUTION_CREATE: '/admin/solution/create',
  SOLUTION_UPDATE: '/admin/solution/update/:id',

  BADGE: '/admin/badge',
  BADGE_CREATE: '/admin/badge/create',
  BADGE_UPDATE: '/admin/badge/update/:id',

  SOLUTION_BADGE: '/admin/solution-badge',
  SOLUTION_BADGE_CREATE: '/admin/solution-badge/create',
  SOLUTION_BADGE_UPDATE: '/admin/solution-badge/update/:id',

  SOLUTION_INPUT_TAG: '/admin/solution-input-tag',
  SOLUTION_INPUT_TAG_CREATE: '/admin/solution-input-tag/create',
  SOLUTION_INPUT_TAG_UPDATE: '/admin/solution-input-tag/update/:id',

  SOLUTION_OUTPUT_TAG: '/admin/solution-output-tag',
  SOLUTION_OUTPUT_TAG_CREATE: '/admin/solution-output-tag/create',
  SOLUTION_OUTPUT_TAG_UPDATE: '/admin/solution-output-tag/update/:id',

  ENTRY_TAG: '/admin/entry-tag',
  ENTRY_TAG_CREATE: '/admin/entry-tag/create',
  ENTRY_TAG_UPDATE: '/admin/entry-tag/update/:id',

  TAG: '/admin/tag',
  TAG_CREATE: '/admin/tag/create',
  TAG_UPDATE: '/admin/tag/update/:id',

  MATERIALSFUNCTION: '/admin/function',
  MATERIALSFUNCTION_CREATE: '/admin/function/create',
  MATERIALSFUNCTION_UPDATE: '/admin/function/update/:id',

  SOLUTION_FUNCTION: '/admin/solution-function',
  SOLUTION_FUNCTION_CREATE: '/admin/solution-function/create',
  SOLUTION_FUNCTION_UPDATE: '/admin/solution-function/update/:id',

  MATCH: '/admin/match',
  MATCH_CREATE: '/admin/match/create',
  MATCH_UPDATE: '/admin/match/update/:id',

  MATCH_IMPACT_OVERVIEW: '/match-overview/:entryId/:solutionId',
  MATCH_IMPACT_OVERVIEW_NO_SOLUTION: '/match-overview/:entryId',
  VALUATION_DASHBOARD: '/valuation-dashboard/:id',
  VALUATION_DASHBOARD_SHORTCODE: '/:groupShortcode/:clientShortcode/valuation-dashboard/:id',

  AI_WIZARD_TEXT: '/ai/text',
  AI_WIZARD_IMAGE: '/ai/images/:id',
  AI_WIZARD_VALUATION: '/ai/valuation',

  SHADOW_ENTRY: '/admin/shadow-entry',
  SHADOW_ENTRY_CREATE: '/admin/shadow-entry/create',
  SHADOW_ENTRY_UPDATE: '/admin/shadow-entry/update/:id',

  MARKETPLACE: '/admin/marketplace',
  MARKETPLACE_CREATE: '/admin/marketplace/create',
  MARKETPLACE_CLIENT_ADMIN_CREATE: '/marketplace/create',
  MARKETPLACE_UPDATE: '/admin/marketplace/update/:id',

  AUCTION: '/admin/auction',
  AUCTION_CREATE: '/admin/auction/create',
  AUCTION_CREATE_STEP_ONE:'/auction/create/details',
  AUCTION_CREATE_STEP_TWO:'/auction/create/items/:id',
  AUCTION_CREATE_STEP_THREE:'/auction/overview/:id',
  AUCTION_CREATE_STEP_FOUR:'/auction/success/:id',
  AUCTION_UPDATE: '/admin/auction/update/:id',

  AUCTION_BID: '/admin/auction-bid',
  AUCTION_BID_CREATE: '/admin/auction-bid/create',
  AUCTION_BID_UPDATE: '/admin/auction-bid/update/:id',

  AUCTION_ENTRY: '/admin/auction-entry',
  AUCTION_ENTRY_CREATE: '/admin/auction-entry/create',
  AUCTION_ENTRY_UPDATE: '/admin/auction-entry/update/:id',
  SEARCH_ENGINE: '/search-engine/:id',
  SEARCH_ENGINE_SHORTCODE: '/:groupShortcode/:clientShortcode/search-engine/:id',

  AUCTIONS : '/auctions',
  VIEW_AUCTIONS: '/auctions/:id?',
  SEARCH_AUCTIONS: '/auctions/:id',
  VIEW_AUCTION: '/auction/:id',
  VIEW_AUCTION_ENTRY: '/auction/entry/:id',

  MARKETPLACES: '/marketplaces',
  VIEW_MARKETPLACES: '/marketplaces/:id?',
  VIEW_MARKETPLACES_SHORTCODE: '/:groupShortcode/:clientShortcode/marketplaces/:id?',
  SEARCH_MARKETPLACES: '/marketplaces/:id',
  SEARCH_MARKETPLACES_SHORTCODE: '/:groupShortcode/:clientShortcode/marketplaces/:id',
  VIEW_MARKETPLACE: '/marketplace/:id',
  VIEW_MARKETPLACE_SHORTCODE: '/:groupShortcode/:clientShortcode/marketplace/:id',
  MARKETPLACE_ADD_REMOVE: '/marketplace/edit/:id',

  MARKETPLACE_ENTRY: '/admin/marketplace-entry',
  MARKETPLACE_ENTRY_CREATE: '/admin/marketplace-entry/create',
  MARKETPLACE_CREATE_STEP_ONE:'/marketplace/create/details',
  MARKETPLACE_CREATE_STEP_TWO:'/marketplace/create/items/:id',
  MARKETPLACE_EDIT_STEP_TWO:'/marketplace/edit/items/:id',
  MARKETPLACE_CREATE_STEP_THREE:'/marketplace/success/:id',
  MARKETPLACE_EDIT_STEP_THREE:'/marketplace/edit/success/:id',


  MARKETPLACE_ENTRY_UPDATE: '/admin/marketplace-entry/update/:id',

  POPULAR_SEARCH: '/admin/popular-search',
  POPULAR_SEARCH_CREATE: '/admin/popular-search/create',

  CLIENT_NAV_LINKS: ['/admin/group', '/admin/client', '/admin/view', '/admin/asset', '/admin/configuration'],
  USER_NAV_LINKS : ['/admin/user', '/verify'],
  ROLE_NAV_LINKS : ['/admin/role', '/admin/role-group', '/admin/role-client', '/admin/view-role', '/admin/form-field-role'],
  ENTRY_NAV_LINKS : ['/admin/entry', '/admin/shadow-entry', '/admin/entry-field', '/admin/entry-edge', '/admin/entry-history', '/admin/entry-field-history', '/admin/entry-tag'],
  FORM_NAV_LINKS : ['/admin/form', '/admin/form-field', '/admin/field-type', '/admin/field-type-option', '/admin/field', '/admin/field-property', '/admin/category'],
  MARKETPLACE_NAV_LINKS : ['/admin/marketplace', '/admin/marketplace-entry'],
  AUCTION_NAV_LINKS : ['/admin/auction', '/admin/auction-bid', '/admin/auction-entry'],
  MATCHMAKING_NAV_LINKS : ['/admin/badge', '/admin/solution', '/admin/tag', '/admin/function', '/admin/solution-badge', '/admin/solution-function', '/admin/solution-input-tag', '/admin/solution-output-tag', '/admin/match'],

  LEAD: '/admin/lead',
  LEAD_CREATE: '/admin/lead/create',
  LEAD_UPDATE: '/admin/lead/update/:id',
  REGISTER_LEAD: '/register',
};

const CONSTANTS = {
  API_ENDPOINTS,
  APP_ROUTES,
};

export enum EntryStatus{
  PUBLIC = 'Public',
  PRIVATE = 'Private',
  IN_REVIEW = 'In Review',
  REPORTED = 'Reported',
  SOLD = 'Sold',
  RESERVED = 'Reserved',
  EXPIRED = 'Expired',
  REUSED = 'Reused',
}

export enum CardType {
  AUCTION = 'Auction',
  MARKETPLACE = 'Marketplace',
  ENTRY = 'Entry',
  AUCTION_ENTRY = 'AuctionEntry',
}

export enum MarketplaceStatus{
  PUBLIC = 'Public',
  PRIVATE = 'Private',
  ANONYMOUS = 'Anonymous',
}

export enum LeadStatus{
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export const marketplaceStatuses = [
  MarketplaceStatus.PRIVATE,
  MarketplaceStatus.PUBLIC,
  MarketplaceStatus.ANONYMOUS,
];

export const auctionStatuses = [
  MarketplaceStatus.PRIVATE,
  MarketplaceStatus.PUBLIC,
];

export const cardTypes = [{ 'label': 'Private', 'value': 'Private' },
  { 'label': 'Public', 'value': 'Public' },
  { 'label': 'In Review', 'value': 'In Review' },
  { 'label': 'Reported', 'value': 'Reported' },
  { 'label': 'Sold', 'value': 'Sold' },
  { 'label': 'Expired', 'value': 'Expired' },
  { 'label': 'Reused', 'value': 'Reused' },
];

export const tulipsUserCardTypes = [
  { 'label': 'Public', 'value': 'Public' },
  { 'label': 'Reserved', 'value': 'Reserved' },
];

export const tulipsAdminCardTypes = [{ 'label': 'Private', 'value': 'Private' },
  { 'label': 'Public', 'value': 'Public' },
  { 'label': 'In Review', 'value': 'In Review' },
  { 'label': 'Reported', 'value': 'Reported' },
  { 'label': 'Sold', 'value': 'Sold' },
  { 'label': 'Expired', 'value': 'Expired' },
  { 'label': 'Reused', 'value': 'Reused' },
  { 'label': 'Reserved', 'value': 'Reserved' },
];


export const marketplaceCardTypes = [{ 'label': 'Private', 'value': 'Private' },
  { 'label': 'Public', 'value': 'Public' },
  { 'label': 'Anonymous', 'value': 'Anonymous' },
];
export const auctionCardTypes = [{ 'label': 'Private', 'value': 'Private' },
  { 'label': 'Public', 'value': 'Public' },
];


export const marketplaceCreateSteps = [
  'Provide Marketplace Details',
  'Select Items For Marketplace',
  'Success',
];

export const marketplaceEditSteps = [
  'Add/Remove Items For Marketplace',
  'Success',
];

export const shareableStatuses = [
  'Public',
  'Anonymous',
];

export enum AuctionStatus{
  LIVE = 'Live',
  CONCLUDED = 'Concluded',
  COMING_SOON = 'Coming Soon',
}

export enum SearchType{
  MARKETPLACE = 'marketplace',
  AUCTION = 'auction',
}


export default CONSTANTS;
