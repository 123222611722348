import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createMarketplaceEntry } from '../../../actions/MarketplaceEntry';
import { getMarketplaces } from '../../../selectors/Marketplace';
import { fetchMarketplaces } from '../../../actions/Marketplace';
import { getEntries } from '../../../selectors/Entry';
import { fetchEntries } from '../../../actions/Entry';

import {
  isPostingMarketplaceEntry,
  postingMarketplaceEntryError,
  postingMarketplaceEntryFailed,
  postingMarketplaceEntryValidationErrors,
  isFetchingMarketplaceEntries,
} from '../../../selectors/MarketplaceEntry';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';

const MarketplaceEntryForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const marketplaces = useSelector(getMarketplaces);
  const entries = useSelector(getEntries);
  const isPosting = useSelector(isPostingMarketplaceEntry);
  const isFetching = useSelector(isFetchingMarketplaceEntries);
  const postingFailed = useSelector(postingMarketplaceEntryFailed);
  const postingError = useSelector(postingMarketplaceEntryError);
  const postingValidationErrors = useSelector(postingMarketplaceEntryValidationErrors);

  const formConfig = [
    { field: 'marketplaceId', validators: [isRequired] },
    { field: 'entryId', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [marketplaceId, setMarketplaceId] = useState('');
  const [entryId, setEntryId] = useState('');

  useEffect(() => {
    dispatch(fetchMarketplaces());
    dispatch(fetchEntries());
  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.MARKETPLACE_ENTRY);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createMarketplaceEntry(marketplaceId, entryId));
    }
  };

  const itemsMarketplaces = marketplaces?.data.map((m) => ({ label: m.title, value: m.id })) || [];
  const itemsEntries = entries?.data.map((e) => ({ label: e.title, value: e.id })) || [];

  const onMarketplaceSelect = (obj: any) => {
    setMarketplaceId(obj.value);
  };

  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };

  return (
        <View>
            <h4>Create MarketplaceEntry</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown
                    v={v}
                    err={err}
                    id="marketplaceId"
                    placeholder="Please select a Marketplace"
                    required
                    label="Marketplace"
                    items={itemsMarketplaces}
                    value={marketplaceId}
                    disabled={isPosting || isFetching}
                    onSelect={onMarketplaceSelect}
                    type="default"
                />
                <DropDown
                    v={v}
                    err={err}
                    id="entryId"
                    placeholder="Please select an Entry"
                    required
                    label="Entry"
                    items={itemsEntries}
                    value={entryId}
                    disabled={isPosting || isFetching}
                    onSelect={onEntrySelect}
                    type="default"
                />

                <div className="row">
                    <div className="col-md-4">
                        <Button
                            disabled={!isFormValid()}
                            isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}
                        />
                    </div>
                </div>
            </form>
        </View>
  );
};

export default MarketplaceEntryForm;
