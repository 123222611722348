import { ApiDataModel, FormError } from './Common';
import { MarketplaceStatus } from '../helpers/constants';

export const FETCH_MARKETPLACES_REQUEST = 'FETCH_MARKETPLACES_REQUEST';
export const FETCH_MARKETPLACES_RESPONSE = 'FETCH_MARKETPLACES_RESPONSE';
export const FETCH_MARKETPLACES_DATATABLE_RESPONSE = 'FETCH_MARKETPLACES_DATATABLE_RESPONSE';
export const FETCH_MARKETPLACES_FAILURE = 'FETCH_MARKETPLACES_FAILURE';

export const FETCH_MARKETPLACE_REQUEST = 'FETCH_MARKETPLACE_REQUEST';
export const FETCH_MARKETPLACE_RESPONSE = 'FETCH_MARKETPLACE_RESPONSE';
export const FETCH_MARKETPLACE_FAILURE = 'FETCH_MARKETPLACE_FAILURE';

export const POST_MARKETPLACE_REQUEST = 'POST_MARKETPLACE_REQUEST';
export const POST_MARKETPLACE_RESPONSE = 'POST_MARKETPLACE_RESPONSE';
export const POST_MARKETPLACE_FAILURE = 'POST_MARKETPLACE_FAILURE';

export interface Marketplace {
  id: string;
  groupId: string;
  groupShortcode: string;
  clientId: string;
  clientTitle: string;
  clientShortcode: string;
  title: string;
  description: string;
  asset: string;
  primaryLogo: string;
  active: boolean;
  visibility: MarketplaceStatus;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface MarketplaceDataModel extends Omit<ApiDataModel, 'data'> {
  entryCounts?: any;
  data: Marketplace[];
}

export interface MarketplaceState {
  marketplaces: MarketplaceDataModel | null,
  marketplacesDatatable: MarketplaceDataModel | null,
  activeMarketplace: Marketplace | null,
  isFetchingMarketplaces: boolean;
  fetchingMarketplacesFailed: boolean;
  postingMarketplaceFailed?: boolean;
  isPostingMarketplace: boolean;
  postingMarketplaceValidationErrors: FormError[] | null;
  postingMarketplaceError: string | null;
}

interface FetchMarketplacesRequestAction {
  type: typeof FETCH_MARKETPLACES_REQUEST;
  payload: null;
}

interface FetchMarketplacesResponseAction {
  type: typeof FETCH_MARKETPLACES_RESPONSE;
  payload: MarketplaceDataModel;
}

interface FetchMarketplacesDatatableResponseAction {
  type: typeof FETCH_MARKETPLACES_DATATABLE_RESPONSE;
  payload: MarketplaceDataModel;
}

interface FetchMarketplacesFailureAction {
  type: typeof FETCH_MARKETPLACES_FAILURE;
  payload: null;
}

interface FetchMarketplaceRequestAction {
  type: typeof FETCH_MARKETPLACE_REQUEST;
  payload: null;
}

interface FetchMarketplaceResponseAction {
  type: typeof FETCH_MARKETPLACE_RESPONSE;
  payload: Marketplace;
}

interface FetchMarketplaceFailureAction {
  type: typeof FETCH_MARKETPLACE_FAILURE;
  payload: null;
}

interface PostMarketplaceRequestAction {
  type: typeof POST_MARKETPLACE_REQUEST;
  payload: null;
}

interface PostMarketplaceResponseAction {
  type: typeof POST_MARKETPLACE_RESPONSE;
  payload: null;
}

interface PostMarketplaceFailureAction {
  type: typeof POST_MARKETPLACE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type MarketplaceActionTypes =
    | FetchMarketplacesRequestAction
    | FetchMarketplacesResponseAction
    | FetchMarketplacesDatatableResponseAction
    | FetchMarketplacesFailureAction
    | PostMarketplaceRequestAction
    | PostMarketplaceResponseAction
    | PostMarketplaceFailureAction
    | FetchMarketplaceRequestAction
    | FetchMarketplaceResponseAction
    | FetchMarketplaceFailureAction;
