import React from 'react';
import assetGallery from '../AssetGallery/AssetGallery';
import './HeadlessView.scss';
import c from '../../helpers/constants';
import { useHistory } from 'react-router-dom';

type Props = {
  title: string;
  clientLogo?: string;
  children?: React.ReactNode;
};


const HeadlessView = (props: Props) => {

  const history = useHistory();
  const toLandingPage = () => {
    history.push(c.APP_ROUTES.LANDING_PAGE);
  };

  return <div className="userform">
    <div className="userform__container">
  <div className="userform--box-shadow">
    <div className="row">
      <div className="col-xxl-5 col-xl-3 col-12">
        <img className="userform__img" src={assetGallery.loginImg} alt="" />
      </div>
      <div className="col-xxl-7 col-xl-8 col-12 p-lg-4 p-4">
        <div className="row mb-lg-3 m-1">
          <div className="col-4">
            <img src={assetGallery.emeLogo} alt="Predefined Logo" className="userform__logo" onClick={toLandingPage}/>
          </div>
          {props.clientLogo && (
              <div className="col-8">
                <img src={`${c.API_ENDPOINTS.ASSET_FILE}/${props.clientLogo}`} alt="Config Logo" className="userform__logo" />
              </div>
          )}
      </div>
        <h4 className="mb-lg-4 text-uppercase w-75 userform__title">{props.title}</h4>
        {props.children}
      </div>
    </div>
    </div>
    </div>
  </div>;
};

export default HeadlessView;



