import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, fetchActiveCategory, updateCategory } from '../../../actions/Category';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import {
  getActiveCategory,
  isPostingCategory,
  postingCategoryError,
  postingCategoryFailed,
  postingCategoryValidationErrors,
  isFetchingCategories,
} from '../../../selectors/Category';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { fetchClients } from '../../../actions/Client';


const CategoryForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeCategory = useSelector(getActiveCategory);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const isPosting = useSelector(isPostingCategory);
  const isFetching = useSelector(isFetchingCategories);
  const postingFailed = useSelector(postingCategoryFailed);
  const postingError = useSelector(postingCategoryError);
  const postingValidationErrors = useSelector(postingCategoryValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CATEGORY_UPDATE);

  const formConfig = [
    { field: 'parentId', validators: [] },
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [parentId, setParentId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [order, setOrder] = useState(0);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    if (editMode) {
      dispatch(fetchActiveCategory(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    setItemsClients(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  }, [clients]);

  useEffect(() => {
    if (activeCategory && editMode) {
      setParentId(activeCategory?.parentId);
      setGroupId(activeCategory?.groupId);
      setClientId(activeCategory?.clientId);
      setOrder(activeCategory?.order);
      setTitle(activeCategory?.title);
      setDescription(activeCategory?.description);
      // @ts-ignore
      setActive(activeCategory?.active === 1 || activeCategory?.active === true);
    }
  }, [activeCategory]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.CATEGORY);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createCategory(parentId, groupId, clientId, order, title, description, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateCategory(activeId, parentId, groupId, clientId, order, title, description, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Category</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
              <Input v={v} err={err}
                     name="parentId"
                     id="parentId"
                     label="Parent"
                     required
                     value={parentId}
                     onChange={setParentId}
                     disabled={isPosting || isFetching}
                     onBlur={setParentId}
                     placeholder="Insert a parent ID"
              />
                <DropDown v={v} err={err}
                          id="groupId"
                          placeholder="Please select a Group"
                          required label="Group"
                          items={itemsGroups}
                          value={groupId}
                          disabled={isPosting || isFetching}
                          onSelect={onGroupSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="clientId"
                          placeholder="Please select a Client"
                          required
                          label="Client"
                          items={itemsClients}
                          disabled={isPosting || isFetching || disabledClient}
                          value={clientId}
                          onSelect={onClientSelect}
                          type="default"
                />
              <Input v={v} err={err}
                     name="order"
                     id="order"
                     label="Order"
                     required
                     value={order}
                     onChange={setOrder}
                     disabled={isPosting || isFetching}
                     onBlur={setOrder}
                     placeholder="Insert an order value"
              />
                <Input v={v} err={err}
                       name="title"
                       id="title"
                       label="Title"
                       required
                       value={title}
                       onChange={setTitle}
                       disabled={isPosting || isFetching}
                       onBlur={setTitle}
                       placeholder="Insert a title"
                />
              <Input v={v} err={err}
                     name="description"
                     id="description"
                     label="Description"
                     required
                     value={description}
                     onChange={setDescription}
                     disabled={isPosting || isFetching}
                     onBlur={setDescription}
                     placeholder="Insert a description"
              />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default CategoryForm;
