import React from 'react';
import './Loading.scss';

function Loading() {
  return <div className="loading">
        <div className="row">
            <div className="spinner-border text-dark" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <span className="sr-only">Loading, please wait.</span>
        </div>
    </div>;
}

export default Loading;
