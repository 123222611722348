import React, { useEffect, useState } from 'react';
import Input from '../../../components/Input';
import View from '../../../components/View/View';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFetchingAuctions,
  isPostingAuction,
  postingAuctionError,
  postingAuctionFailed, postingAuctionValidationErrors,
} from '../../../selectors/Auction';
import { isRequired } from '../../../helpers/validators';
import { addDays } from 'date-fns';
import { useValidation } from '../../../hooks/ValidationHook';
import { getActiveConfiguration } from '../../../selectors/Configuration';
import { createAuctionFromSteps } from '../../../actions/Auction';
import c, { auctionStatuses } from '../../../helpers/constants';
import DateSelect from '../../../components/DateSelect';
import Button from '../../../components/Button';
import { Alert } from 'react-bootstrap';
import FileSelector from '../../../components/FileSelector';
import Stepper from '../../../components/Stepper';
import './AuctionWizard.scss';
import { ensurePositiveInteger } from '../../../helpers/functions';
import DropDown from '../../../components/DropDown';

const StepOne = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingAuction);
  const isFetching = useSelector(isFetchingAuctions);
  const activeConfiguration = useSelector(getActiveConfiguration);
  const postingFailed = useSelector(postingAuctionFailed);
  const postingError = useSelector(postingAuctionError);
  const postingValidationErrors = useSelector(postingAuctionValidationErrors);

  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
    { field: 'startingBid', validators: [isRequired] },
    { field: 'bidIncrement', validators: [isRequired] },
    {  field: 'visibility', validators: [isRequired] },
  ];
  const steps = [
    'Provide Auction Details',
    'Select Items For Auction',
    'Overview',
    'Success',
  ];
  const currentDate = new Date();
  const nextDay = addDays(currentDate, 1);

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [assetId, setAsset] = useState('');
  const [assetError, setAssetError] = useState('');
  const [startingBid, setStartingBid] = useState('');
  const [startDateTime, setStartDateTime] = useState(currentDate);
  const [endDateTime, setEndDateTime] = useState(nextDay);
  const [visibility, setVisibility] = useState('Private');
  const [bidIncrement, setBidIncrement] = useState('');

  const groupId = activeConfiguration?.groupId;
  const clientId = activeConfiguration?.clientId;
  const itemsStatuses = auctionStatuses?.map(r => ({ label: r, value: r })) || [];


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
    }
  }, [isPosting]);

  useEffect(() => {
    if (assetId) {
      setAssetError('');
    }
  }, [assetId]);

  const onSubmit = async () => {
    if (triggerValidation() && groupId && clientId) {
      if (assetId) {
        setHasSubmitted(true);
        dispatch(createAuctionFromSteps(groupId, clientId, title, description, assetId, startingBid, startDateTime.toISOString(), endDateTime.toISOString(), bidIncrement, visibility, history ));
      } else {
        setAssetError('Please select an asset for the auction.');
      }
    }
  };

  const onVisibilitySelect = (obj: any) => {
    setVisibility(obj.value);
  };

  const onCancel = async () => {
    history.push(c.APP_ROUTES.AUCTIONS);
  };

  const handleFilePick = (id: any) => {
    setAsset(id);
  };

  const handleFileUnPick = () => {
    setAsset('');
  };

  const handleBid = (val: any) => {
    const bid = ensurePositiveInteger(val);
    setStartingBid(bid);
  };

  const handleBidIncrement = (val: any) => {
    const bid = ensurePositiveInteger(val);
    setBidIncrement(bid);
  };


  const handleFirstDate = (val: any) => {
    setStartDateTime(val);
    if (endDateTime <= val) {
      setEndDateTime(addDays(val, 1));
    }
  };

  return (
      <View title={'Provide Auction Details'}>
          <div className="container-fluid">
                  {postingError && (
                      <Alert className="mt-3" variant="danger">
                          {postingError}
                      </Alert>
                  )}
                  {assetError && (
                      <Alert className="mt-3" variant="danger">
                          {assetError}
                      </Alert>
                  )}
              <div className="row justify-content-center mb-4">
                  <div className="col-8">
                      <Stepper steps={steps} activeStep={0} hideMobileLabels={true} />
                  </div>
              </div>
              <form>
                  <div className="mx-4">
                        <div className='row'>
                            <div className="col-md-12 auction-wizard__box">
                                <div className='row'>
                                    <div className="col-md-6 ">
                                        <Input v={v} err={err}
                                       name="title"
                                       id="title"
                                       label="Title"
                                       required
                                       value={title}
                                       onChange={setTitle}
                                       disabled={isPosting || isFetching}
                                       onBlur={setTitle}
                                       placeholder="Insert a title"
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Input v={v} err={err}
                                               name="description"
                                               id="description"
                                               label="Description"
                                               required
                                               value={description}
                                               onChange={setDescription}
                                               disabled={isPosting || isFetching}
                                               onBlur={setDescription}
                                               placeholder="Insert a description"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12 auction-wizard__image-container'>
                                <div className="auction-wizard__image-container__label">{'Primary Image*'}</div>
                              <FileSelector
                                  type="images"
                                  groupId={groupId}
                                  dynamicId = {'primary'}
                                  clientId={clientId}
                                  onPick={handleFilePick}
                                  onUnPick={handleFileUnPick}
                                  picked={assetId}
                              />
                            </div>
                        </div>
                  <div className='row auction-wizard__box'>
                      <div className="col-md-6 col-12 p-3">
                          { assetId ?
                              <img loading="lazy" src={`${c.API_ENDPOINTS.ASSET_FILE}/${assetId}`} alt="" className="auction-wizard__img-fluid"  />
                            :
                         <div className="auction-wizard__img-placeholder"></div>}
                      </div>
                      <div className="col-md-6 col-12">
                          <Input v={v} err={err}
                                 name="startingBid"
                                 id="startingBid"
                                 label="Starting Bid"
                                 type="number"
                                 required
                                 value={startingBid}
                                 onChange={handleBid}
                                 disabled={isPosting || isFetching}
                                 onBlur={handleBid}
                                 placeholder="Insert a starting Bid"
                          />
                          <Input v={v} err={err}
                                 name="bidIncrement"
                                 id="bidIncrement"
                                 label="Bid Increment"
                                 type="number"
                                 required
                                 value={bidIncrement}
                                 onChange={handleBidIncrement}
                                 disabled={isPosting || isFetching}
                                 onBlur={handleBidIncrement}
                                 placeholder="Insert a Bid Increment"
                          />
                          <DateSelect
                              onChange={handleFirstDate}
                              title="Start Date & Time"
                              value={startDateTime}
                              minDateTime={currentDate}
                              required
                          />

                          <DateSelect
                              onChange={setEndDateTime}
                              title="End Date & Time"
                              value={endDateTime}
                              minDateTime={addDays(startDateTime, 1)}
                              required
                          />
                          <DropDown v={v} err={err}
                                    id="visibility"
                                    placeholder="Please select an auction visibility"
                                    required
                                    label="Visibility"
                                    items={itemsStatuses}
                                    disabled={isPosting || isFetching}
                                    value={visibility}
                                    onSelect={onVisibilitySelect}
                                    type="default"
                          />
                      </div>
                      </div>
                      <div className="row justify-content-between">
                          <div className="col-auto">
                              <Button  onClick={onCancel} title='Cancel'/>
                          </div>
                          <div className="col-auto">
                              <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                      onClick={() =>  onSubmit()}
                                      title={ 'Next'}/>
                          </div>
                      </div>
                  </div>
              </form>
          </div>
      </View>
  );
};

export default StepOne;

