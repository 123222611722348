import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createAuctionBid, fetchActiveAuctionBid, updateAuctionBid } from '../../../actions/AuctionBid';
import { getAuctions } from '../../../selectors/Auction';
import {  fetchAuctions } from '../../../actions/Auction';
import { getEntries } from '../../../selectors/Entry';
import { fetchEntries } from '../../../actions/Entry';

import {
  isPostingAuctionBid,
  getActiveAuctionBid,
  postingAuctionBidError,
  postingAuctionBidFailed,
  postingAuctionBidValidationErrors,
  isFetchingAuctionBids,
} from '../../../selectors/AuctionBid';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { fetchUsers } from '../../../actions/User';
import { useEditMode } from '../../../hooks/EditModeHook';
import { getUsers } from '../../../selectors/User';
import Input from '../../../components/Input';

const AuctionBidForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeAuctionBid = useSelector(getActiveAuctionBid);
  const auctions = useSelector(getAuctions);
  const entries = useSelector(getEntries);
  const users = useSelector(getUsers);
  const isPosting = useSelector(isPostingAuctionBid);
  const isFetching = useSelector(isFetchingAuctionBids);
  const postingFailed = useSelector(postingAuctionBidFailed);
  const postingError = useSelector(postingAuctionBidError);
  const postingValidationErrors = useSelector(postingAuctionBidValidationErrors);
  const [editMode, activeId] = useEditMode(c.APP_ROUTES.AUCTION_BID_UPDATE);

  const formConfig = [
    { field: 'auctionId', validators: [isRequired] },
    { field: 'entryId', validators: [isRequired] },
    { field: 'userId', validators: [isRequired] },
    { field: 'amount', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [auctionId, setAuctionId] = useState('');
  const [entryId, setEntryId] = useState('');
  const [userId, setUserId] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    dispatch(fetchAuctions());
    dispatch(fetchEntries());
    dispatch(fetchUsers());
    if (editMode) {
      dispatch(fetchActiveAuctionBid(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeAuctionBid && editMode) {
      setAuctionId(activeAuctionBid?.auctionId);
      setEntryId(activeAuctionBid?.entryId);
      setUserId(activeAuctionBid?.userId);
      setAmount(activeAuctionBid?.amount);

    }
  }, [activeAuctionBid]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.AUCTION_BID);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createAuctionBid(auctionId, entryId, userId, amount));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateAuctionBid(activeId, auctionId, entryId, userId, amount));
    }
  };

  const itemsAuctions = auctions?.data.map((m) => ({ label: m.title, value: m.id })) || [];
  const itemsEntries = entries?.data.map((e) => ({ label: e.title, value: e.id })) || [];
  const itemsUsers = users?.data.map((e) => ({ label: e.username, value: e.id })) || [];
  const onAuctionSelect = (obj: any) => {
    setAuctionId(obj.value);
  };

  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };

  const onUserSelect = (obj: any) => {
    setUserId(obj.value);
  };

  return (
        <View>
            <h4>Update AuctionBid</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown
                    v={v}
                    err={err}
                    id="auctionId"
                    placeholder="Please select an Auction"
                    required
                    label="Auction"
                    items={itemsAuctions}
                    value={auctionId}
                    disabled={isPosting || isFetching}
                    onSelect={onAuctionSelect}
                    type="default"
                />
                <DropDown
                    v={v}
                    err={err}
                    id="entryId"
                    placeholder="Please select an Entry"
                    required
                    label="Entry"
                    items={itemsEntries}
                    value={entryId}
                    disabled={isPosting || isFetching}
                    onSelect={onEntrySelect}
                    type="default"
                />

              <DropDown
                  v={v}
                  err={err}
                  id="userId"
                  placeholder="Please select a User"
                  required
                  label="User"
                  items={itemsUsers}
                  value={userId}
                  disabled={isPosting || isFetching}
                  onSelect={onUserSelect}
                  type="default"
              />

              <Input v={v} err={err}
                     name="amount"
                     id="amount"
                     label="Amount"
                     required
                     value={amount}
                     onChange={setAmount}
                     disabled={isPosting || isFetching}
                     onBlur={setAmount}
                     placeholder="Insert a Bid Increment"
              />

                <div className="row">
                  <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => editMode ? onSave() : onSubmit()}
                            title={editMode ? 'SAVE' : 'CREATE'}/>
                  </div>
                </div>
            </form>
        </View>
  );
};

export default AuctionBidForm;
