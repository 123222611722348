import React, { useState } from 'react';
import './Tabs.scss';

export type Tab = {
  label: string;
  icon?: string;
  content: React.ReactNode;
};

type Props = {
  tabs: Tab[];
  activeTabIndex?: number;
};

const Tabs: React.FC<Props> = ({ tabs, activeTabIndex }) => {
  const [activeTab, setActiveTab] = useState<number>(activeTabIndex ? activeTabIndex : 0);
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  if (!tabs || tabs.length === 0 || activeTab < 0 || activeTab >= tabs.length) {
    return null;
  }

  return (
        <div className="tabs">
            <div className="tabs__header">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab ${activeTab === index ? 'active' : ''}`}
                        onClick={() => handleTabClick(index)}
                    >
                        {tab.icon && (
                            <img src={tab.icon} className="tabs__icon"/>
                        )}
                        {tab.label}
                    </div>
                ))}
            </div>
            {tabs[activeTab].content && (
                <div className="tabs__content">{tabs[activeTab].content}</div>
            )}
        </div>
  );
};

export default Tabs;
