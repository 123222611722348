import React, { useEffect, useState } from 'react';
import './TextArea.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Validator } from '../../hooks/ValidationHook';



type Props = {
  value?: string | number | null;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?:number;
  name?: string;
  id?: string;
  label?: string;
  required?: boolean;
  subtitle?: string;
  bgColor?: 'grey' | 'white';
  style?: 'rounded' | 'normal';
  disabled?: boolean;
  v?: any;
  err?: (id: string) => string[];
  validate?: any,
  validators?: Validator[]
  errors?: string[];
  maxLength?: number;
};


const TextArea = (props: Props) => {
  const {
    onBlur = () => { },
    onChange = () => { },
    onKeyDown = () => { },
    disabled = false,
    required = false,
    rows = 3,
    placeholder = '',
    value = '',
    name = '',
    subtitle = '',
    id = '',
    label = '',
    err = () => [],
    bgColor = 'grey',
    v = () => {},
    maxLength,
  } = props;

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);

  const innerOnChange = (e: any) => {
    setTouched(true);
    onChange(e.target.value);
    if (v && typeof v === 'function') {
      v(id, e.target.value);
    }
  };
  const innerOnBlur = (e: any) => {
    setTouched(true);
    onBlur(e.target.value);
    if (v && typeof v === 'function') {
      v(id, e.target.value);
    }
  };

  const errors = err(id);

  return (
    <div className="text-area">
      {label && <label htmlFor={id} className='form-label'>{label}<span className={`form-label ${errors.length > 0 && 'error-lbl'}`}>{required && '*'}</span></label>}
      <div className='row'>
        <div className="custom-input col-12 mb-2">
          <textarea
            disabled={disabled}
            rows={rows}
            required={required}
            className={`form-control ${bgColor} ${errors.length > 0 && 'error'}`}
            value={value || ''} name={name} id={id}
            onBlur={innerOnBlur}
            onChange={innerOnChange}
            onKeyDown={onKeyDown} // pass the onKeyDown prop to the input
            placeholder={placeholder}
            maxLength={maxLength}
          />
          <small id={`${id}help`} className="form-text text-muted">{subtitle}</small>
        </div>

        {errors.map((e: string, i: number) => (
            <div key={i} className='col-auto error-container'>
              {e}
            </div>
        ))}


      </div>
    </div>

  );
};

export default TextArea;
