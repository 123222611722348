import { createStore, applyMiddleware, combineReducers, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import categoryReducer from '../reducers/Category';
import fieldTypeReducer from '../reducers/FieldType';
import fieldReducer from '../reducers/Field';
import fieldTypeOptionReducer from '../reducers/FieldTypeOption';
import fieldPropertyReducer from '../reducers/FieldProperty';
import authReducer from '../reducers/Auth';
import roleReducer from '../reducers/Role';
import roleGroupReducer from '../reducers/RoleGroup';
import roleClientReducer from '../reducers/RoleClient';
import userReducer from '../reducers/User';
import groupReducer from '../reducers/Group';
import clientReducer from '../reducers/Client';
import assetReducer from '../reducers/Asset';
import entryReducer from '../reducers/Entry';
import entryHistoryReducer from '../reducers/EntryHistory';
import entryFieldHistoryReducer from '../reducers/EntryFieldHistory';
import entryFieldReducer from '../reducers/EntryField';
import entryEdgeReducer from '../reducers/EntryEdge';
import formReducer from '../reducers/Form';
import formFieldReducer from '../reducers/FormField';
import formFieldRoleReducer from '../reducers/FormFieldRole';
import viewReducer from '../reducers/View';
import viewRoleReducer from '../reducers/ViewRole';
import configurationReducer from '../reducers/Configuration';

import badgeReducer from '../reducers/Badge';
import entryTagReducer from '../reducers/EntryTag';
import materialsFunctionReducer from '../reducers/MaterialsFunction';
import solutionReducer from '../reducers/Solution';
import solutionBadgeReducer from '../reducers/SolutionBadge';
import solutionFunctionReducer from '../reducers/SolutionFunction';
import solutionInputTagReducer from '../reducers/SolutionInputTag';
import solutionOutputTagReducer from '../reducers/SolutionOutputTag';
import tagReducer from '../reducers/Tag';
import matchReducer from '../reducers/Match';
import wizardReducer from '../reducers/Wizard';
import shadowEntryReducer from '../reducers/ShadowEntry';
import marketplaceReducer from '../reducers/Marketplace';
import marketplaceEntryReducer from '../reducers/MarketplaceEntry';
import auctionReducer from '../reducers/Auction';
import auctionBidReducer from '../reducers/AuctionBid';
import auctionEntryReducer from '../reducers/AuctionEntry';
import popularSearchReducer from '../reducers/PopularSearch';
import leadReducer from '../reducers/Lead';

const rootReducer = combineReducers({
  category: categoryReducer,
  fieldType: fieldTypeReducer,
  fieldTypeOption: fieldTypeOptionReducer,
  field: fieldReducer,
  fieldProperty: fieldPropertyReducer,
  auth: authReducer,
  role: roleReducer,
  user: userReducer,
  group: groupReducer,
  client: clientReducer,
  asset: assetReducer,
  entry: entryReducer,
  entryField: entryFieldReducer,
  entryEdge: entryEdgeReducer,
  entryHistory: entryHistoryReducer,
  entryFieldHistory: entryFieldHistoryReducer,
  form: formReducer,
  formField: formFieldReducer,
  formFieldRole: formFieldRoleReducer,
  view: viewReducer,
  roleGroup: roleGroupReducer,
  roleClient: roleClientReducer,
  viewRole: viewRoleReducer,
  configuration: configurationReducer,
  badge: badgeReducer,
  entryTag: entryTagReducer,
  materialsFunction: materialsFunctionReducer,
  solution: solutionReducer,
  solutionBadge: solutionBadgeReducer,
  solutionFunction: solutionFunctionReducer,
  solutionInputTag: solutionInputTagReducer,
  solutionOutputTag: solutionOutputTagReducer,
  tag: tagReducer,
  match: matchReducer,
  wizard: wizardReducer,
  shadowEntry: shadowEntryReducer,
  marketplace: marketplaceReducer,
  marketplaceEntry: marketplaceEntryReducer,
  auction: auctionReducer,
  auctionBid : auctionBidReducer,
  auctionEntry : auctionEntryReducer,
  popularSearch: popularSearchReducer,
  lead: leadReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;

export default store;
