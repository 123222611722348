import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createAuctionEntry, fetchActiveAuctionEntry, fetchAuctionEntries, updateAuctionEntry } from '../../../actions/AuctionEntry';

import {
  getActiveAuctionEntry,
  isPostingAuctionEntry,
  postingAuctionEntryError,
  postingAuctionEntryFailed,
  postingAuctionEntryValidationErrors,
  isFetchingAuctionEntries,
} from '../../../selectors/AuctionEntry';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import DropDown from '../../../components/DropDown';
import DateSelect from '../../../components/DateSelect';
import { getAuctions } from '../../../selectors/Auction';
import { getEntries } from '../../../selectors/Entry';
import { fetchAuctions } from '../../../actions/Auction';
import { fetchEntries } from '../../../actions/Entry';



const AuctionEntryForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeAuctionEntry = useSelector(getActiveAuctionEntry);
  const auctions = useSelector(getAuctions);
  const entries = useSelector(getEntries);
  const isPosting = useSelector(isPostingAuctionEntry);
  const isFetching = useSelector(isFetchingAuctionEntries);
  const postingFailed = useSelector(postingAuctionEntryFailed);
  const postingError = useSelector(postingAuctionEntryError);
  const postingValidationErrors = useSelector(postingAuctionEntryValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.AUCTION_ENTRY_UPDATE);

  const formConfig = [

    { field: 'auctionId', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [auctionId, setAuctionId] = useState('');
  const [entryId, setEntryId] = useState('');
  const [startingBid, setStartingBid] = useState('');
  const [startDateTime, setStartDateTime] = useState(new Date());
  const [endDateTime, setEndDateTime] = useState(new Date());
  const [bidIncrement, setBidIncrement] = useState('');




  useEffect(() => {
    dispatch(fetchAuctionEntries());
    dispatch(fetchAuctions());
    dispatch(fetchEntries());
    if (editMode) {
      dispatch(fetchActiveAuctionEntry(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeAuctionEntry && editMode) {
      setAuctionId(activeAuctionEntry?.auctionId);
      setEntryId(activeAuctionEntry?.entryId);
      setStartingBid(activeAuctionEntry?.startingBid);
      setStartDateTime(new Date(activeAuctionEntry?.startDateTime));
      setEndDateTime(new Date(activeAuctionEntry?.endDateTime));
      setBidIncrement(activeAuctionEntry?.bidIncrement);

    }
  }, [activeAuctionEntry]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.AUCTION_ENTRY);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createAuctionEntry(auctionId, entryId, startingBid, startDateTime.toISOString(), endDateTime.toISOString(), bidIncrement ));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateAuctionEntry(activeId, auctionId, entryId, startingBid, startDateTime.toISOString(), endDateTime.toISOString(), bidIncrement));
    }
  };

  const itemsAuctions = auctions?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsEntries = entries?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onAuctionSelect = (obj: any) => {
    setAuctionId(obj.value);

  };

  const onEntrySelect = (obj: any) => {
    setEntryId(obj.value);
  };





  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} AuctionEntry</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>

                <DropDown v={v} err={err}
                          id="auctionId"
                          placeholder="Please select an Auction"
                          required label="Auction"
                          items={itemsAuctions}
                          value={auctionId}
                          disabled={isPosting || isFetching}
                          onSelect={onAuctionSelect}
                          type="default"
                />
                <DropDown v={v} err={err}
                          id="entryId"
                          placeholder="Please select an Entry"
                          required
                          label="Entry"
                          items={itemsEntries}
                          disabled={isPosting || isFetching }
                          value={entryId}
                          onSelect={onEntrySelect}
                          type="default"
                />

                <Input v={v} err={err}
                       name="startingBid"
                       id="startingBid"
                       label="Starting Bid"
                       value={startingBid}
                       onChange={setStartingBid}
                       disabled={isPosting || isFetching}
                       onBlur={setStartingBid}
                       placeholder="Insert a starting Bid"
                />
                <DateSelect
                    onChange={setStartDateTime}
                    value={startDateTime}
                />

                <DateSelect
                    onChange={setEndDateTime}
                    value={endDateTime}
                />

                <Input v={v} err={err}
                       name="bidIncrement"
                       id="bidIncrement"
                       label="Bid Increment"
                       required
                       value={bidIncrement}
                       onChange={setBidIncrement}
                       disabled={isPosting || isFetching}
                       onBlur={setBidIncrement}
                       placeholder="Insert a Bid Increment"
                />



                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default AuctionEntryForm;
