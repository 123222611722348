import { ApiDataModel } from './Common';

export const FETCH_ASSETS_REQUEST = 'FETCH_ASSETS_REQUEST';
export const FETCH_ASSETS_RESPONSE = 'FETCH_ASSETS_RESPONSE';
export const FETCH_DYNAMIC_ASSETS_REQUEST = 'FETCH_DYNAMIC_ASSETS_REQUEST';
export const FETCH_DYNAMIC_ASSETS_RESPONSE = 'FETCH_DYNAMIC_ASSETS_RESPONSE';
export const FETCH_ASSETS_FAILURE = 'FETCH_ASSETS_FAILURE';

export const FETCH_ASSET_REQUEST = 'FETCH_ASSET_REQUEST';
export const FETCH_ASSET_RESPONSE = 'FETCH_ASSET_RESPONSE';
export const FETCH_UPLOADED_ASSETS_RESPONSE = 'FETCH_UPLOADED_ASSETS_RESPONSE';
export const FETCH_ASSET_FAILURE = 'FETCH_ASSET_FAILURE';

export const POST_ASSET_REQUEST = 'POST_ASSET_REQUEST';
export const POST_ASSET_RESPONSE = 'POST_ASSET_RESPONSE';
export const POST_ASSET_FAILURE = 'POST_ASSET_FAILURE';

export const CLEAR_UPLOADED_ASSETS = 'CLEAR_UPLOADED_ASSETS';


export interface Asset {
  id: string;
  groupId: string;
  clientId: string;
  userId: string;
  type: string;
  extension: string;
  mime: string;
  path: string;
  title: string;
  created: Date;
  createdBy: Date;
  updated: Date;
  updateBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface AssetDataModel extends Omit<ApiDataModel, 'data'> {
  data: Asset[];
  identifier: string;
}

export interface UploadedAssetDataModel extends Omit<ApiDataModel, 'data'> {
  data: Asset[];
  identifier: string;
}



export interface DynamicAssetDataModel extends Omit<ApiDataModel, 'data'> {
  id: string;
  data: Asset[];

}

export interface AssetState {
  assets: AssetDataModel | null,
  dynamicAssets: DynamicAssetDataModel | [] | DynamicAssetDataModel[],
  uploadedAssets : UploadedAssetDataModel | null,
  identifier: string;
  activeAsset: Asset | null,
  isFetchingAssets: boolean;
  fetchingAssetsFailed: boolean;
  isPostingAsset?: boolean;
  postingAssetErrors?: string[] | null
}

interface FetchAssetsRequestAction {
  type: typeof FETCH_ASSETS_REQUEST;
  payload: null;
}


interface FetchDynamicAssetsRequestAction {
  type: typeof FETCH_DYNAMIC_ASSETS_REQUEST;
  payload: null;
}

interface FetchAssetsResponseAction {
  type: typeof FETCH_ASSETS_RESPONSE;
  payload: AssetDataModel;
}

interface FetchUploadedAssetsResponseAction {
  type: typeof FETCH_UPLOADED_ASSETS_RESPONSE;
  payload: UploadedAssetDataModel;
  identifier: string;
}

interface FetchDynamicAssetsResponseAction {
  type: typeof FETCH_DYNAMIC_ASSETS_RESPONSE;
  payload: AssetDataModel;
}

interface FetchAssetsFailureAction {
  type: typeof FETCH_ASSETS_FAILURE;
  payload: null;
}

interface FetchAssetRequestAction {
  type: typeof FETCH_ASSET_REQUEST;
  payload: null;
}

interface FetchAssetResponseAction {
  type: typeof FETCH_ASSET_RESPONSE;
  payload: Asset;
}

interface FetchAssetFailureAction {
  type: typeof FETCH_ASSET_FAILURE;
  payload: null;
}

interface PostAssetRequestAction {
  type: typeof POST_ASSET_REQUEST;
  payload: null;
}

interface PostAssetResponseAction {
  type: typeof POST_ASSET_RESPONSE;
  payload: null;
}

interface PostAssetFailureAction {
  type: typeof POST_ASSET_FAILURE;
  payload: null;
}

interface ClearUploadedAssetAction {
  type: typeof CLEAR_UPLOADED_ASSETS;
  payload: null;
}

export type AssetActionTypes =
    | FetchAssetsRequestAction
    | FetchDynamicAssetsRequestAction
    | FetchAssetsResponseAction
    | FetchDynamicAssetsResponseAction
    | FetchAssetsFailureAction
    | PostAssetRequestAction
    | PostAssetResponseAction
    | PostAssetFailureAction
    | FetchAssetRequestAction
    | FetchAssetResponseAction
    | FetchUploadedAssetsResponseAction
    | FetchAssetFailureAction
    | ClearUploadedAssetAction;

