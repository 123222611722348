import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import AssetGallery from '../../components/AssetGallery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './LandingPage.scss';
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons';
import Button from '../../components/Button';
import { useHistory } from 'react-router-dom';
import LandingPageView from '../../components/LandingPageView';
import c from '../../helpers/constants';
import ProductsCard from './ProductsCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPopularEntries,
  getRecentlyAddedEntries,
  getRecentlyReusedEntries,
  isFetchingPopularEntries, isFetchingRecentlyAddedEntries,
  isFetchingRecentlyReusedEntries,
} from '../../selectors/Entry';
import { getPopularSearches } from '../../selectors/PopularSearch';
import { fetchPopularEntries, fetchRecentlyAddedEntries, fetchRecentlyReusedEntries } from '../../actions/Entry';
import Loading from '../../components/Loading';
import { removeDuplicates } from '../../helpers/functions';
import { fetchPopularSearches } from '../../actions/PopularSearch';
import { DataTableParamsModel } from '../../types/Common';

function LandingPage() {

  const history = useHistory();
  const dispatch = useDispatch();
  const recentEntries = useSelector(getRecentlyAddedEntries);
  const reusedEntries = useSelector(getRecentlyReusedEntries);
  const isLoadingPopularEntries = useSelector(isFetchingPopularEntries);
  const isLoadingRecentlyReusedEntries = useSelector(isFetchingRecentlyReusedEntries);
  const isLoadingRecentlyAddedEntries = useSelector(isFetchingRecentlyAddedEntries);
  const popularSearches = useSelector(getPopularSearches);
  const popularEntries = useSelector(getPopularEntries);
  const [uniqueSearchTerms, setUniqueSearchTerms] = useState<string[]>([]);
  const isLoading = isLoadingPopularEntries || isLoadingRecentlyAddedEntries || isLoadingRecentlyReusedEntries;

  useEffect(() => {
    const dataTableParams: DataTableParamsModel = {
      colKey: 'popularity',
      sortDirection: 'desc',
      paging: '10',
    };
    dispatch(fetchPopularSearches(dataTableParams));
  }, [dispatch]);


  useEffect(() => {
    if (popularSearches?.data) {
      const searchTerms = popularSearches.data.map(search => search.searchTerm);
      const uniqueTerms = removeDuplicates(searchTerms);
      setUniqueSearchTerms(uniqueTerms);
    }
  }, [popularSearches]);

  useEffect(() => {
    dispatch(fetchRecentlyAddedEntries({ searchField: 'status', searchWord: 'Public', fetchParams: { visibilityPublic: true }, paging : '4' }));
    dispatch(fetchRecentlyReusedEntries({ searchField: 'status', searchWord: 'Reused', fetchParams: { visibilityPublic: true }, paging : '4' }));
    if (uniqueSearchTerms && uniqueSearchTerms.length > 0) {
      const searchWords = uniqueSearchTerms.slice(0, 3);
      dispatch(fetchPopularEntries({ baseSearch: { 'all': searchWords,  'status': ['Public', 'Reused'] }, fetchParams: { visibilityPublic: true }, paging : '4' }));
    }
  }, [dispatch, uniqueSearchTerms]);
  
  
    type ArrowProps = {
      className?: string,
      style?: React.CSSProperties,
      onClick?: any,
    };
    const NextArrow = (props: ArrowProps) => {
      const { className, style, onClick } = props;
      return (
            <div
                className={className}
                style={{ ...style, display: 'block', zIndex: 2 }}
                onClick={onClick}
            >
                <ChevronRight size={25} className={''}/>
            </div>
      );
    };

    const PrevArrow = (props: ArrowProps) => {
      const { className, style, onClick } = props;
      return (
            <div
                className={className}
                style={{ ...style, display: 'block', zIndex: 2 }}
                onClick={onClick}
            >
                <ChevronLeft size={25}/>
            </div>
      );
    };

    const goToRecentlyAdded = () => {
      history.push(c.APP_ROUTES.SEARCH_RESULT + '?type=recently_added');
    };

    const goToRecentlyReused = () => {
      history.push(c.APP_ROUTES.SEARCH_RESULT + '?type=recently_reused');
    };

    const goToPopularProducts = () => {
      history.push(c.APP_ROUTES.SEARCH_RESULT + '?type=popular_products');
    };



    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const goToPage = (url: string) => {
      history.push(url);
    };

    const slidesData = [
      {
        image: AssetGallery.landingPageSlideLeaf,
        title: 'TRANSFORMING WASTE INTO WEALTH',
        subtitle: 'Catalysing connections in the B2B landscape, our digital matching platform seamlessly transforms surplus materials into valuable resources.<br> Embrace EME to streamline your circular goals, diminish your environmental impact, and optimise financial gains',
        url: 'page/view/static/9eFVGcJb5W',
      },
      {
        image: AssetGallery.businessEff,
        title: 'ENHANCING BUSINESS EFFICIENCY',
        subtitle: 'Our digital matching platform revolutionizes the way businesses handle surplus materials by connecting them with potential buyers',
        url: 'page/view/static/TC223m6IhQ',
      },
      {
        image: AssetGallery.envirImpact,
        title: 'DIMINISHING ENVIRONMENTAL IMPACT',
        subtitle: 'Our digital matching platform plays a crucial role in diminishing the environmental impact of businesses.<br> By transforming waste into wealth, companies can significantly reduce their carbon footprint and lessen their dependence on landfills.',
        url: 'page/view/static/zjDrEpLK34',
      },
    ];

    return (
        <LandingPageView showFooter={false} popularSearches = {popularSearches}>
            <div className="landing-page">
                <div className="row">
                    <div className="col-12">
                        <div className="slider-container">
                            <Slider {...settings}>
                                {slidesData.map((slide, index) => (
                                    <div key={index} className="slide">
                                        <div className="image-container">
                                            <div className="gradient-overlay"></div>
                                            <img src={slide.image} alt={`Slide ${index + 1}`}/>
                                            <div className="overlay-text">
                                                <h4 className="title">{slide.title}</h4>
                                                <div className="subtitle"
                                                     dangerouslySetInnerHTML={{ __html: slide.subtitle }}></div>
                                                <Button title={'See More'} onClick={() => goToPage(slide.url)}/>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                            <div className="card-container w-100">
                                {isLoading ?  <Loading /> :
                                <div className="row justify-content-around">
                                    <div className="bg-white shadow p-4 custom-col card-container-border">
                                        <h4 className="mb-2">RECENTLY ADDED</h4>
                                        <ProductsCard type={'recently_added'} recentEntries={recentEntries}/>
                                        <div className="w-100 mt-4">
                                            <Button title={'See More'} onClick={goToRecentlyAdded}/>
                                        </div>
                                    </div>
                                    <div className="bg-white shadow p-4 custom-col card-container-border">
                                        <h4 className="mb-2">RECENTLY REUSED ITEMS</h4>
                                        <ProductsCard type={'recently_reused'} reusedEntries={reusedEntries}/>
                                        <div className="w-100 mt-4">
                                            <Button title={'See More'} onClick={goToRecentlyReused}/>
                                        </div>
                                    </div>
                                    <div className="bg-white shadow p-4 custom-col card-container-border">
                                        <h4 className="mb-2">POPULAR</h4>
                                        <ProductsCard type={'popular_items'} popularEntries={popularEntries}/>
                                        <div className="w-100 mt-4">
                                            <Button title={'See More'} onClick={goToPopularProducts}/>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className="bg-gray"></div>
                        <div className="container-fluid bg-white shadow-lg footer text-muted home-page-footer">
                            <div className="d-flex justify-content-between">
                                <div className="d-flex justify-content-start">
                                    <div className="p-2">&copy;2024 EME. All Rights Reserved</div>
                                    <div className="p-2"><a href="#">Privacy Policy</a></div>
                                    <div className="p-2">
                                        <a href={`${c.APP_ROUTES.CORE}/ExcessMaterialsExchange-Terms-Conditions.pdf`}>Terms and Conditions</a>
                                    </div>
                                    <div className="p-2"><a href="mailto:support@excessmaterialsexchange.com">Contact Support</a></div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="p-2"><a href="https://www.facebook.com/excessmaterialsexchange/" target="_blank" rel="noreferrer"><img src={AssetGallery.facebookIcon} alt={'Facebook'}/></a></div>
                                    <div className="p-2"><a href="https://www.linkedin.com/company/excess-materials-exchange/" target="_blank" rel="noreferrer"><img src={AssetGallery.linkedinIcon} alt={'Linkedin'}/></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LandingPageView>
    );
}

export default LandingPage;
