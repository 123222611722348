import React from 'react';
import './Modal.scss';
import { Button, Modal } from 'react-bootstrap';

type Props = {
  title?: string;
  children?: React.ReactNode;
  onCta?: () => void;
  onHide?: () => void;
  cta?: string;
  show?: boolean;
  buttonDisabled?: boolean;
};

const EMEModal = (props: Props) => {
  const {
    title, children, onCta = () => {}, cta, show = false, onHide = () => {}, buttonDisabled = false,
  } = props;
  return (

        <Modal centered className="eme-modal" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <div className="modal-header__left">
                    <Modal.Title>{title}</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='modal-description'>{children}</div>
            </Modal.Body>
            {cta && <Modal.Footer>
                <Button onClick={onCta} disabled={buttonDisabled} variant="secondary" className='modal-btn'>{cta}</Button>
            </Modal.Footer>}
        </Modal>
  );
};

export default EMEModal;
