import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import CheckBox from '../../../components/CheckBox';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchActiveUser, updateUser } from '../../../actions/User';
import { getActiveUser, isPostingUser, postingUserError, postingUserFailed, postingUserValidationErrors, isFetchingUsers } from '../../../selectors/User';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { Alert } from 'react-bootstrap';
import Modal from '../../../components/Modal';
import FilePicker from '../../../components/FilePicker';
import { X } from 'react-bootstrap-icons';
import noImage from '../../../assets/images/no-preview.png';
import { getAssets } from '../../../selectors/Asset';
import { getLoggedInRole, getLoggedInUser } from '../../../selectors/Auth';
import { fetchRoleClients } from '../../../actions/RoleClient';
import { getRoleClients } from '../../../selectors/RoleClient';
import { generatePassword } from '../../../helpers/functions';


const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = useSelector(getRoleClients);
  const activeUser = useSelector(getActiveUser);
  const loggedInRole = useSelector(getLoggedInRole);
  const loggedInUser = useSelector(getLoggedInUser);
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);
  const [primaryModal, setPrimaryModal] = useState(false);
  const [asset, setAsset] = useState('');



  const onAddPrimaryImage = () => {
    setPrimaryModal(true);
  };

  const onCloseModal = () => {
    setPrimaryModal(false);
  };
  
  const onDismiss = () => {
    setPrimaryModal(false);
  };

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.USER_UPDATE);

  const formConfig = [
    { field: 'roleId', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'confirmPassword', validators: [] },
    { field: 'username', validators: [isRequired] },
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
  ];

  if (!editMode) {
    formConfig.push({ field: 'password', validators: [isRequired] });
  }

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [roleId, setRoleId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [location, setLocation] = useState('');
  const [occupation, setOccupation] = useState('');
  const [bio, setBio] = useState('');
  const [active, setActive] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [verified, setVerified] = useState(!!(loggedInRole && loggedInRole?.accessLevel <= 1000));
  const allAssets = useSelector(getAssets);

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveUser(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (loggedInRole && loggedInRole?.accessLevel <= 1000) {
      setVerified(!!(loggedInRole && loggedInRole?.accessLevel <= 1000));
      setIsAdmin(true);
    }
  }, [loggedInRole]);

  useEffect(() => {
    if (loggedInUser && loggedInUser.clients.length > 0) {
      const clientIds = loggedInUser.clients.map(client => client.clientId);
      dispatch(fetchRoleClients({ searchField: 'clientId', searchWord: clientIds }));
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (activeUser && editMode) {
      setRoleId(activeUser?.roleId);
      setEmail(activeUser?.email);
      setUsername(activeUser?.username);
      setFirstName(activeUser?.firstName);
      setLastName(activeUser?.lastName);
      setLocation(activeUser?.location);
      setOccupation(activeUser?.occupation);
      setBio(activeUser?.bio);
      if (activeUser?.avatar){
        setAsset(activeUser?.avatar);
      } else {
        setAsset('');
      }
      // @ts-ignore
      setActive(activeUser?.active === 1 || activeUser?.active === true);
      // @ts-ignore
      setVerified(activeUser?.verified === 1 || activeUser?.verified === true);
    }
  }, [activeUser]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.USER);
    }
  }, [isPosting]);

  useEffect(() => {
    if (!editMode){
      const generatedPassword = generatePassword();
      setPassword(generatedPassword);
      setConfirmPassword(generatedPassword);
    }
  }, []);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createUser(roleId, email, password, username, firstName, lastName, location, occupation, bio, asset, active, verified));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateUser(activeId, roleId, email, password, username, firstName, lastName, location, occupation, bio, asset, active, verified));
    }
  };

  const itemsRoles = roles?.data
    .filter(r => loggedInRole && r.roleAccessLevel >= loggedInRole.accessLevel)
    .reduce((acc, r) => {
      if (!acc.set.has(r.roleId)) {
        acc.set.add(r.roleId);
        acc.result.push({ label: r.roleTitle, value: r.roleId });
      }
      return acc;
    }, { set: new Set(), result: [] as { label: string; value: string }[] })
    .result || [];

  const onRoleSelect = (obj: any) => {
    setRoleId(obj.value);
  };

  const filterAssets = (assetId: string) => {
    return allAssets?.data.find(item => item.id === assetId);
  };


  const renderEntryAssetInput = () => {
    return <div>
      <label className="form-label">{'Profile Avatar'}</label>
      <div>
        <Button  title="Select" onClick={ onAddPrimaryImage}/>
      </div>

      <>
        <Modal show={primaryModal} title="Add Assets" cta="Save" onCta={onCloseModal} onHide={onDismiss}>
          <FilePicker type="images" picked={asset} onPick={(id) => setAsset(id)}
                      onUnPick={() => setAsset('')} />
        </Modal>

      </>
      <div className='row'>
        {asset &&
            <div className='col-md-4'>
              <div className="img-delete" onClick={() => setAsset('')}>
                {/* @ts-ignore */}
                <X />
              </div>
              <img src={`${c.API_ENDPOINTS.ASSET_FILE}/${asset}`} alt={asset} className="img-preview" onError={(event) => event.currentTarget.src = noImage} />
              <p className='file-pill'>{filterAssets(asset)?.title} <span
                  className="badge rounded-pill bg-info text-dark">.{filterAssets(asset)?.extension}</span></p>
            </div>}
      </div>
    </div>;
  };


  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} User</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="roleId"
                placeholder="Please select a Role"
                required label="Role"
                items={itemsRoles}
                value={roleId}
                disabled={isPosting || isFetching}
                onSelect={onRoleSelect}
                type="default"
      />
      <Input v={v} err={err}
             name="email"
             id="email"
             label="Email"
             required
             value={email}
             onChange={setEmail}
             disabled={isPosting || isFetching}
             onBlur={setEmail}
             placeholder="Insert a title"
      />
      {!editMode && <Input v={v} err={err}
             required
             name="password"
             id="password"
             type="password"
             label="Password"
             value={password}
             disabled={isPosting || isFetching}
             onChange={setPassword}
             onBlur={setPassword}
             placeholder="Insert a password"
      />}
      {!editMode && <Input v={v} err={err}
             name="confirmPassword"
             id="confirmPassword"
             label="Confirm Password"
             required
             type="password"
             value={confirmPassword}
             onChange={setConfirmPassword}
             disabled={isPosting || isFetching}
             onBlur={setConfirmPassword}
             placeholder=""
      />}
      {renderEntryAssetInput()}
      <Input v={v} err={err}
             required
             name="username"
             id="username"
             label="Username"
             value={username}
             disabled={isPosting || isFetching}
             onChange={setUsername}
             onBlur={setUsername}
             placeholder="Insert a username"
      />
      <Input v={v} err={err}
             required
             name="firstName"
             id="firstName"
             label="First Name"
             value={firstName}
             disabled={isPosting || isFetching}
             onChange={setFirstName}
             onBlur={setFirstName}
             placeholder="Insert a first name"
      />
      <Input v={v} err={err}
             required
             name="lastName"
             id="lastName"
             label="Last Name"
             value={lastName}
             disabled={isPosting || isFetching}
             onChange={setLastName}
             onBlur={setLastName}
             placeholder="Insert a last name"
      />
      <Input v={v} err={err}
             required
             name="location"
             id="location"
             label="Location"
             value={location}
             disabled={isPosting || isFetching}
             onChange={setLocation}
             onBlur={setLocation}
             placeholder="Insert a location"
      /><Input v={v} err={err}
               required
               name="occupation"
               id="occupation"
               label="Occupation"
               value={occupation}
               disabled={isPosting || isFetching}
               onChange={setOccupation}
               onBlur={setOccupation}
               placeholder="Insert a occupation"
    /><Input v={v} err={err}
             required
             name="bio"
             id="bio"
             label="Bio"
             value={bio}
             disabled={isPosting || isFetching}
             onChange={setBio}
             onBlur={setBio}
             placeholder="Insert a user bio"
    />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      {isAdmin && <CheckBox label="Is Verified?" checked={verified} onChange={setVerified}/>}
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default UserForm;




