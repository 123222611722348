import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import View from '../../components/View';
import './AuctionEntry.scss';
import { ensurePositiveInteger, formatTime, getUserToken } from '../../helpers/functions';
import { fetchingAuctionsFailed, isFetchingAuctions } from '../../selectors/Auction';
import { getActiveEntry, isFetchingEntries } from '../../selectors/Entry';
import { fetchActiveEntry } from '../../actions/Entry';
import c from '../../helpers/constants';
import Button from '../../components/Button';
import { fetchActiveAuctionEntry } from '../../actions/AuctionEntry';
import { getActiveAuctionEntry, isFetchingAuctionEntries } from '../../selectors/AuctionEntry';
import { fetchEntryFields } from '../../actions/EntryField';
import { getEntryFields } from '../../selectors/EntryField';
import CarouselPreview from '../../components/CarouselPreview/CarouselPreview';
import { getLoggedInUser } from '../../selectors/Auth';
import { useRouteMatch } from 'react-router-dom';
import { fetchAuctionBids, placeAuctionBid } from '../../actions/AuctionBid';
import { getAuctionBids, isFetchingAuctionBids } from '../../selectors/AuctionBid';
import { utcToZonedTime } from 'date-fns-tz';

function AuctionViewRenderer() {
  const dispatch = useDispatch();
  const entry = useSelector(getActiveEntry);
  const auctionEntry = useSelector(getActiveAuctionEntry);
  const auctionBids = useSelector(getAuctionBids);
  const carouselFields = useSelector(getEntryFields);
  const isLoadingAuctions = useSelector(isFetchingAuctions);
  const isLoadingAuctionEntries = useSelector(isFetchingAuctionEntries);
  const isLoadingAuctionBids = useSelector(isFetchingAuctionBids);
  const isLoadingEntries = useSelector(isFetchingEntries);
  const fetchFailed = useSelector(fetchingAuctionsFailed);
  const user = useSelector(getLoggedInUser);
  let matchUpdate = useRouteMatch(c.APP_ROUTES.VIEW_AUCTION_ENTRY);
  // @ts-ignore
  const auctionEntryId = matchUpdate?.params?.id;
  const isLoading =  isLoadingAuctionBids || isLoadingAuctionEntries || isLoadingEntries || isLoadingAuctions || auctionEntryId !== auctionEntry?.id;

  const [message, setMessage] = useState('');
  const [time, setTime] = useState('0');
  const [receivedMessages, setReceivedMessages] = useState([{ type:'', content:'' }]);
  const [currentBid, setCurrentBid] = useState(Number( 0));
  const [minimumBid, setMinimumBid] = useState( 0);
  const [winning, setWinning] = useState(false);
  const [endOfAuction, setEndOfAuction] = useState(false);

  const primaryImage = `${c.API_ENDPOINTS.ASSET_FILE}/${entry?.assetId}`;
  let assets = carouselFields?.data?.map(a => a.value.split(',').map(u => `${c.API_ENDPOINTS.ASSET_FILE}/${u}`)).flat() ?? [];

  if (primaryImage) {
    if (assets && assets.length > 0) {
      assets.unshift(primaryImage);
    } else {
      assets = [primaryImage];
    }
  }

  useEffect(() => {
    if (getUserToken()) {
      dispatch(fetchActiveAuctionEntry(auctionEntryId));
    }
  }, [dispatch, auctionEntryId]);

  useEffect(() => {
    if (getUserToken() && auctionEntry?.entryId && auctionEntryId == auctionEntry?.id) {
      dispatch(fetchActiveEntry(auctionEntry.entryId));
      dispatch(fetchEntryFields({
        searchField: 'entryId',
        searchWord: auctionEntry.entryId,
        baseSearch: { 'fieldType': 'carousel' },
      }));
      dispatch(fetchAuctionBids({
        searchField: 'auctionId',
        searchWord: auctionEntry.auctionId,
        baseSearch: { 'entryId': auctionEntry.entryId },
        colKey: 'amount',
        sortDirection: 'desc',
      }));
    }
  }, [auctionEntry, auctionEntryId]);

  useEffect(() => {
    if (auctionBids?.data) {
      const maxAmount = auctionBids.data.reduce((max, current) => {
        return parseInt(current.amount) > parseInt(max?.amount as string) ? current : max;
      }, auctionBids.data[0]);

      setWinning(maxAmount && maxAmount.userId === user?.id);
    }
  }, [auctionBids, user]);

  useEffect(() => {
    if (getUserToken() && auctionBids?.data[0] && auctionEntry?.bidIncrement) {
      setCurrentBid(Number(auctionBids?.data[0].amount));
      setMinimumBid(Number(auctionBids?.data[0].amount) + Number(auctionEntry?.bidIncrement));
    } else {
      setMinimumBid(Number(auctionEntry?.startingBid));
      setCurrentBid(0);
    }
  }, [auctionBids, auctionEntry, auctionEntryId]);


  useEffect(() => {
    if (user && auctionEntry){
    // Enable pusher logging - don't include this in production
      (window as any).Pusher.logToConsole = true;

      const pusher = new (window as any).Pusher('c29de59b35bba4e24ef0', {
        cluster: 'eu',
      });
      const channel = pusher.subscribe('eme-test-app-development');
      channel.bind(auctionEntryId, (data: any) => {

        const parsedData = JSON.parse(data.message);

        if (parsedData.type === 'broadcast' && auctionEntry?.bidIncrement && parsedData.userId !== user?.id){
          setCurrentBid(parsedData.amount);
          setMinimumBid(Number(parsedData.amount) + Number(auctionEntry?.bidIncrement));
          setWinning(false);
          const receivedMessage = { content :parsedData.message, type: parsedData.type ? parsedData.type : 'error' };
          setReceivedMessages((prevMessages) => {
            return [receivedMessage, ...prevMessages.slice(0, 2)];
          });
        }

      });

      // Clean up Pusher connection when component unmounts
      return () => {
        pusher.disconnect();
      };
    }
  }, [user, auctionEntry]); // Empty dependency array ensures useEffect runs once after initial render


  const sendMessage = async () => {
    if (auctionEntry && user){
      try {
        const response = await dispatch(placeAuctionBid(auctionEntry.id, user.id, message));

        // @ts-ignore
        const receivedMessage = { content :response.message, type: response.type ? response.type : 'error' };
        setReceivedMessages((prevMessages) => {
          return [receivedMessage, ...prevMessages.slice(0, 2)];
        });
        // @ts-ignore
        if (receivedMessage.type === 'success' && response.data.amount && auctionEntry?.bidIncrement){
          // @ts-ignore
          setCurrentBid(response.data.amount);
          // @ts-ignore
          setMinimumBid(Number(response.data.amount) + Number(auctionEntry?.bidIncrement));
          setWinning(true);
        }

      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };


  useEffect(() => {
    const initialDate = utcToZonedTime(new Date().toISOString(), 'UTC');
    const initialCTime = initialDate.getTime();
    if (auctionEntry && auctionEntry?.endDateTime){
      if (new Date(auctionEntry.endDateTime).getTime() <= initialCTime){
        setEndOfAuction(true);
      } else {
        setEndOfAuction(false);
      }
    }
    const intervalId = setInterval(() => {
      if (auctionEntry && auctionEntry.endDateTime) {
        const cDate = utcToZonedTime(new Date().toISOString(), 'UTC');
        const cTime = cDate.getTime();
        setTime(formatTime(new Date(auctionEntry.endDateTime).getTime() - cTime));
        if (new Date(auctionEntry.endDateTime).getTime() <= cTime){
          setEndOfAuction(true);
        }
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [auctionEntry]);

  const handleBack = () => {
    window.history.back();
  };

  return (
        <View isLoading={isLoading}>
            <div className="page" >
                    <div className="container auction-entry__box">
                        <div className="row mt-3">
                            <div className="col-3 col-md-4 col-xl-6 justify-content-start">
                                <Button title={'Back'} onClick={handleBack} />
                            </div>
                            <div className="col-9 col-md-8 col-xl-6 justify-content-end auction-entry__time align-items-center">
                                <div className='auction-entry__time__text'>
                                  {endOfAuction ? 'COMPLETED' : 'Time Left: ' + time}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-md-5 me-4">
                                <div className="col-12 auction-entry__title mx-2">
                                    {entry?.title.toUpperCase()}
                                </div>
                                <div className="col-12 ">
                                    <CarouselPreview imageUrls={assets} imagesToShow={1}/>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mt-3">
                                <div className="row py-2 justify-content-between">

                                    <div className="col-4 col-sm-3 auction-entry__title justify-content-start d-flex align-items-center">
                                        YOUR BID
                                    </div>
                                  <div className='d-none d-md-block col-2 col-sm-1 col-md-1'/>

                                  {!endOfAuction &&
                                      <>
                                        <div className="col-4 col-md-3 auction-entry__current-bid justify-content-end align-items-end">
                                      <div className='auction-entry__label--bold'>Current Bid</div>
                                            {currentBid === 0 ? 'Be the first to place a bet' : currentBid  }
                                    </div>
                                  <div className="col-3 col-md-3 auction-entry__current-bid justify-content-end align-items-end">
                                      {winning ? 'You are winning' : 'Bid in order to take the lead'}
                                  </div>
                                      </>
                                  }

                                </div>
                              {!endOfAuction ?
                                  <>
                                  <div className='col-12 auction-entry__minimum-bid'>Minimum allowed
                                    bid: {isLoadingAuctionBids ? 'Loading' : minimumBid} GBP</div>
                                  <div className="col-12 py-3 justify-content-center">
                                    <input
                                        type="text"
                                        className='form-control'
                                        placeholder="Insert an amount"
                                        value={message}
                                        onChange={(e) => setMessage(ensurePositiveInteger(e.target.value))}
                                    />
                                  </div>
                                <div className="col-12 justify-content-start">
                                <Button onClick={sendMessage} disabled={!message} title={'Place Bid'}/>
                                </div>
                                <div className="auction-entry__messages">
                              {receivedMessages.map((msg, index) => (
                                <div className={`col-12 p-1 my-1 auction-entry__message-${msg.type}`} key={index}>{msg.content}</div>
                              ))}
                                </div>
                                  </> :
                                <div className="auction-entry__current-bid auction-entry__current-bid--grey">
                                    {currentBid !== 0 ? `This auction has been completed. The winning bid is: ${currentBid} GBP` : 'This auction has been completed.'}
                                  </div>
                              }
                                <div className="col-12">
                                    <div className="col-12 auction-entry__title-description">
                                        Description
                                    </div>
                                    <div className="col-12">
                                        {entry?.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                {fetchFailed && (
                    <p>Failed to fetch auction.</p>
                )}
            </div>
        </View>
  );


}

export default AuctionViewRenderer;
