import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getAssets, isFetchingAssets } from '../../../selectors/Asset';
import { fetchAssets } from '../../../actions/Asset';
import DataTable from '../../../components/DataTable';
import { storeAsset } from '../../../actions/Asset';
import { Asset } from '../../../types/Asset';
import Modal from '../../../components/Modal';

const AssetDataTable = () => {

  const assets = useSelector(getAssets);
  const isLoading = useSelector(isFetchingAssets);
  const [toggle, setToggle] = useState(false);
  const [asset, setActiveAsset] = useState<Asset | null>();
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onCreate = () => {
    history.push(c.APP_ROUTES.ASSET_CREATE);
  };

  const onDismiss = () => {
    setToggle(false);
  };

  const onArchive = (row: Asset) => {
    setActiveAsset(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (asset?.id) {
      await storeAsset(asset?.id);
      setRefetch(!refetch);
    }
    setActiveAsset(null);
  };

  const config = {
    columns: [
      { key: 'title', label: 'File Title', mobileFriendly: false },
      { key: 'mime', label: 'Mime', mobileFriendly: true },
      { key: 'id', label: 'Identifier', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [{ icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return (<View title="Assets" onAddNew={onCreate}>
        <DataTable fetchFunction={fetchAssets} data={assets?.data || []}
                   isLoading={isLoading} paging={assets?.paging || []} config={config} dependencies={refetch}/>
        <Modal show={toggle} title="Archive Asset" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
          Are you sure you want to delete this asset?
        </Modal>
    </View>
  );
};

export default AssetDataTable;

