import {
  FETCH_ASSETS_REQUEST,
  FETCH_DYNAMIC_ASSETS_REQUEST,
  FETCH_ASSETS_FAILURE,
  FETCH_ASSETS_RESPONSE,
  FETCH_DYNAMIC_ASSETS_RESPONSE,
  AssetState,
  AssetActionTypes,
  FETCH_ASSET_RESPONSE,
  FETCH_ASSET_FAILURE,
  FETCH_ASSET_REQUEST,
  POST_ASSET_FAILURE,
  POST_ASSET_REQUEST,
  POST_ASSET_RESPONSE, FETCH_UPLOADED_ASSETS_RESPONSE,
  CLEAR_UPLOADED_ASSETS,
} from '../types/Asset';

const initialState: AssetState = {
  assets: null,
  dynamicAssets: [],
  identifier: '',
  uploadedAssets: null,
  activeAsset: null,
  isFetchingAssets: false,
  fetchingAssetsFailed: false,
  isPostingAsset: false,
};

const assetsReducer = (
  state = initialState,
  action: AssetActionTypes,
): AssetState => {
  switch (action.type) {
    case FETCH_ASSETS_REQUEST:
      return {
        ...state,
        assets: null,
        uploadedAssets: null,
        isFetchingAssets: true,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSETS_RESPONSE:
      return {
        ...state,
        assets: action.payload || [],
        isFetchingAssets: false,
        fetchingAssetsFailed: false,
      };
      

    case FETCH_UPLOADED_ASSETS_RESPONSE:
      return {
        ...state,
        uploadedAssets: action.payload || [],
        identifier: action.identifier,
        isFetchingAssets: false,
        fetchingAssetsFailed: false,
      };


    case FETCH_DYNAMIC_ASSETS_REQUEST:
      return {
        ...state,
        isFetchingAssets: true,
        fetchingAssetsFailed: false,
      };

    case FETCH_DYNAMIC_ASSETS_RESPONSE:
      const { payload } = action;
      // Ensure state.assets is an array or fallback to an empty array
      const currentAssets = Array.isArray(state.dynamicAssets) ? state.dynamicAssets : [];
      // Check if the asset with the same identifier already exists
      const existingAssetIndex = currentAssets.findIndex(({ id }) => id === payload.identifier);
      // If it exists, replace it; otherwise, add the new asset
      const updatedAssets = existingAssetIndex !== -1
        ? [
          ...currentAssets.slice(0, existingAssetIndex),
          { id: payload.identifier, data: payload.data, paging: payload.paging },
          ...currentAssets.slice(existingAssetIndex + 1),
        ]
        : [...currentAssets, { id: payload.identifier, data: payload.data, paging: payload.paging }];

      return {
        ...state,
        dynamicAssets: updatedAssets,
        isFetchingAssets: false,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSETS_FAILURE:
      return {
        ...state,
        assets: null,
        isFetchingAssets: false,
        fetchingAssetsFailed: true,
      };
    case FETCH_ASSET_REQUEST:
      return {
        ...state,
        activeAsset: null,
        isFetchingAssets: true,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSET_RESPONSE:
      return {
        ...state,
        activeAsset: action.payload,
        isFetchingAssets: false,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSET_FAILURE:
      return {
        ...state,
        activeAsset: null,
        isFetchingAssets: false,
        fetchingAssetsFailed: true,
      };
    case POST_ASSET_FAILURE:
      return {
        ...state,
        isPostingAsset: false,
        postingAssetErrors: null,
      };
    case POST_ASSET_REQUEST:
      return {
        ...state,
        isPostingAsset: true,
        postingAssetErrors: null,
      };
    case POST_ASSET_RESPONSE:
      return {
        ...state,
        isPostingAsset: false,
        postingAssetErrors: null,
      };
    case CLEAR_UPLOADED_ASSETS:
      return {
        ...state,
        uploadedAssets: null,
      
      };
    default:
      return state;
  }
};

export default assetsReducer;
