import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createFieldType, fetchActiveFieldType, updateFieldType } from '../../../actions/FieldType';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveFieldType,
  isPostingFieldType,
  postingFieldTypeError,
  postingFieldTypeFailed,
  postingFieldTypeValidationErrors,
  isFetchingFieldTypes,
} from '../../../selectors/FieldType';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';


const FieldTypeForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeFieldType = useSelector(getActiveFieldType);
  const isPosting = useSelector(isPostingFieldType);
  const isFetching = useSelector(isFetchingFieldTypes);
  const postingFailed = useSelector(postingFieldTypeFailed);
  const postingError = useSelector(postingFieldTypeError);
  const postingValidationErrors = useSelector(postingFieldTypeValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.FIELD_TYPE_UPDATE);

  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveFieldType(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeFieldType && editMode) {
      setTitle(activeFieldType?.title);
      setDescription(activeFieldType?.description);
      // @ts-ignore
      setActive(activeFieldType?.active === 1 || activeFieldType?.active === true);
    }
  }, [activeFieldType]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.FIELD_TYPE);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createFieldType(title, description, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateFieldType(activeId, title, description, active));
    }
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} FieldType</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <Input v={v} err={err}
                       name="title"
                       id="title"
                       label="Title"
                       required
                       value={title}
                       onChange={setTitle}
                       disabled={isPosting || isFetching}
                       onBlur={setTitle}
                       placeholder="Insert a title"
                />
                <Input v={v} err={err}
                       required
                       name="description"
                       id="description"
                       label="Description"
                       value={description}
                       disabled={isPosting || isFetching}
                       onChange={setDescription}
                       onBlur={setDescription}
                       placeholder="Insert a description"
                />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default FieldTypeForm;
