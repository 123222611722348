import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getClients, isFetchingClients } from '../../../selectors/Client';
import { duplicateClient, fetchClients, storeClient } from '../../../actions/Client';
import { Client } from '../../../types/Client';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';
import DropDown from '../../../components/DropDown';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { getLoggedInRole } from '../../../selectors/Auth';


const ClientDataTable = () => {

  const clients = useSelector(getClients);
  const loggedInRole = useSelector(getLoggedInRole);
  const isLoading = useSelector(isFetchingClients);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [toggleShortcodes, setToggleShortcodes] = useState(false);
  const [client, setActiveClient] = useState<Client | null>();
  const [toggleDuplicateModal, setToggleDuplicateModal] = useState(false);
  const [clientToDuplicate, setClientToDuplicate] = useState('');
  const [duplicateTitle, setDuplicateTitle] = useState('');
  const [refetch, setRefetch] = useState(false);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.CLIENT_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.CLIENT_UPDATE, row.id));
  };

  const onShortcodes = (row: Client) => {
    setActiveClient(row);
    setToggleShortcodes(true);
  };

  const onOk = () => {
    setToggleShortcodes(false);
  };

  const hideDuplicate = () => {
    setToggleDuplicateModal(false);
  };

  const showDuplicate = () => {
    setToggleDuplicateModal(true);
  };

  const onArchive = (row: Client) => {
    setActiveClient(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (client?.id) {
      await storeClient(client?.id);
      setRefetch(!refetch);
    }
    setActiveClient(null);
  };

  const itemsClients = clients?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onClientSelect = (obj: any) => {
    setClientToDuplicate(obj.value);
  };
  
  const onConfirmDuplication = () => {
    setToggleDuplicateModal(false);
    if (clientToDuplicate && duplicateTitle) {
      dispatch(duplicateClient(clientToDuplicate, duplicateTitle));
    }
  };
  
  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'shortcode', label: 'Shortcode', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'email', label: 'Email', mobileFriendly: true },
      { key: 'phone', label: 'Phone', mobileFriendly: true },
      { key: 'website', label: 'Website', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive },
      { icon: 'eye', label: '', onClick: onShortcodes }],
    hideButton: true,
  };

  return (
      <View title="Clients" onAddNew={onCreate}>
      {loggedInRole?.accessLevel === 0 && <Button title={'Duplicate Client'} onClick={showDuplicate}/>}
      <DataTable fetchFunction={fetchClients} data={clients?.data || []}
                 isLoading={isLoading} paging={clients?.paging || []} config={config} dependencies={refetch} />
      <Modal show={toggle} title="Archive Client" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to delete this client?
      </Modal>
      <Modal show={toggleShortcodes} title={'Client Shortcode'} onCta={onOk} onHide={onOk}>
        <a href={`${formatUrl(c.APP_ROUTES.DASHBOARD_SHORTCODE, client?.groupShortcode, client?.shortcode)}`}>
          {`${window.location.protocol}//${window.location.host}${formatUrl(c.APP_ROUTES.DASHBOARD_SHORTCODE, client?.groupShortcode, client?.shortcode)}`}        </a>
      </Modal>
      <Modal show={toggleDuplicateModal} title={'Duplicate Client'} cta={'Duplicate'} onCta={onConfirmDuplication} onHide={hideDuplicate}>
        <div style = {{ zIndex : '99' }}><DropDown items={itemsClients} value={clientToDuplicate} onSelect={onClientSelect}/></div>
        <Input name="title"
               id="title"
               label="New Title"
               required
               value={duplicateTitle}
               onChange={setDuplicateTitle}
               disabled={!clientToDuplicate}
               onBlur={setDuplicateTitle}
               placeholder="Insert new client name"
        />
      </Modal>
    </View>
  );
};

export default ClientDataTable;

