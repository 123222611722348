import { ApiDataModel, FormError } from './Common';

export const FETCH_VIEWS_REQUEST = 'FETCH_VIEWS_REQUEST';
export const FETCH_VIEWS_RESPONSE = 'FETCH_VIEWS_RESPONSE';
export const FETCH_VIEWS_FAILURE = 'FETCH_VIEWS_FAILURE';

export const FETCH_DUPLICATE_VIEWS_REQUEST = 'FETCH_DUPLICATE_VIEWS_REQUEST';
export const FETCH_DUPLICATE_VIEWS_RESPONSE = 'FETCH_DUPLICATE_VIEWS_RESPONSE';
export const FETCH_DUPLICATE_VIEWS_FAILURE = 'FETCH_DUPLICATE_VIEWS_FAILURE';

export const FETCH_VIEW_REQUEST = 'FETCH_VIEW_REQUEST';
export const FETCH_VIEW_RESPONSE = 'FETCH_VIEW_RESPONSE';
export const FETCH_VIEW_FAILURE = 'FETCH_VIEW_FAILURE';

export const POST_VIEW_REQUEST = 'POST_VIEW_REQUEST';
export const POST_VIEW_RESPONSE = 'POST_VIEW_RESPONSE';
export const POST_VIEW_FAILURE = 'POST_VIEW_FAILURE';

export interface View {
  id: string;
  groupId: string;
  clientId: string;
  clientTitle: string;
  formId: string;
  viewId: string;
  order: number;
  title: string;
  contents: string;
  active: boolean;
  created: Date;
  createdBy: Date;
  updated: Date;
  updatedBy: Date;
  archived: Date;
  archivedBy: Date;
}

export interface ViewDataModel extends Omit<ApiDataModel, 'data'> {
  data: View[];
}

export interface ViewState {
  views: ViewDataModel | null,
  duplicateViews: ViewDataModel | null,
  activeView: View | null,
  isFetchingViews: boolean;
  fetchingViewsFailed: boolean;
  postingViewFailed?: boolean;
  isPostingView: boolean;
  postingViewValidationErrors: FormError[] | null;
  postingViewError: string | null;
}

interface FetchViewsRequestAction {
  type: typeof FETCH_VIEWS_REQUEST;
  payload: null;
}

interface FetchViewsResponseAction {
  type: typeof FETCH_VIEWS_RESPONSE;
  payload: ViewDataModel;
}

interface FetchViewsFailureAction {
  type: typeof FETCH_VIEWS_FAILURE;
  payload: null;
}

interface FetchDuplicateViewsRequestAction {
  type: typeof FETCH_DUPLICATE_VIEWS_REQUEST;
  payload: null;
}

interface FetchDuplicateViewsResponseAction {
  type: typeof FETCH_DUPLICATE_VIEWS_RESPONSE;
  payload: ViewDataModel;
}

interface FetchDuplicateViewsFailureAction {
  type: typeof FETCH_DUPLICATE_VIEWS_FAILURE;
  payload: null;
}

interface FetchViewRequestAction {
  type: typeof FETCH_VIEW_REQUEST;
  payload: null;
}

interface FetchViewResponseAction {
  type: typeof FETCH_VIEW_RESPONSE;
  payload: View;
}

interface FetchViewFailureAction {
  type: typeof FETCH_VIEW_FAILURE;
  payload: null;
}

interface PostViewRequestAction {
  type: typeof POST_VIEW_REQUEST;
  payload: null;
}

interface PostViewResponseAction {
  type: typeof POST_VIEW_RESPONSE;
  payload: null;
}

interface PostViewFailureAction {
  type: typeof POST_VIEW_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ViewActionTypes =
    | FetchViewsRequestAction
    | FetchViewsResponseAction
    | FetchViewsFailureAction
    | FetchDuplicateViewsRequestAction
    | FetchDuplicateViewsResponseAction
    | FetchDuplicateViewsFailureAction
    | PostViewRequestAction
    | PostViewResponseAction
    | PostViewFailureAction
    | FetchViewRequestAction
    | FetchViewResponseAction
    | FetchViewFailureAction;
