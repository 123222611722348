import emeLogo from '../../assets/images/svgs/EME-Transparent-small.png';
import marketplaceImg from '../../assets/images/design_assets/market-icon.svg';
import catalogueImg from '../../assets/images/design_assets/inventory-icon.svg';
import infoImg from '../../assets/images/design_assets/info-icon.svg';
import clientImg from '../../assets/images/design_assets/client-icon.svg';
import userImg from '../../assets/images/design_assets/user-icon.svg';
import roleImg from '../../assets/images/design_assets/role-icon.svg';
import entryImg from '../../assets/images/design_assets/entry-icon.svg';
import formImg from '../../assets/images/design_assets/form-icon.svg';
import auctionsImg from '../../assets/images/design_assets/auctions-icon.svg';
import sortByImg from '../../assets/images/design_assets/sortby-icon.svg';
import filterImg from '../../assets/images/design_assets/filters-icon.svg';
import noImgPlaceholder from '../../assets/images/no-preview.png';
import addAssetImg from '../../assets/images/design_assets/add-file-img-icon.svg';
import tagImg from '../../assets/images/design_assets/tag-icon.svg';
import xIcon from '../../assets/images/design_assets/x-icon.svg';
import editImg from '../../assets/images/design_assets/edith-icon.svg';
import xImg from '../../assets/images/design_assets/x-icon.svg';
import xImgDark from '../../assets/images/design_assets/x-icon2.svg';
import calendarImg from '../../assets/images/design_assets/calendar-svg.svg';
import eyeImg from '../../assets/images/design_assets/eye-icon.svg';
import blackPinImg from '../../assets/images/design_assets/pin-2-black.svg';
import bluePinImg from '../../assets/images/design_assets/pin-2-icon.svg';
import searchesTag from '../../assets/images/design_assets/searches-tag-icon.svg';
import downloadImg from '../../assets/images/design_assets/download-icon.svg';
import fullScreenImg from '../../assets/images/design_assets/zoom-icon.svg';
import loginImg from '../../assets/images/design_assets/login.jpg';
import plusImg from '../../assets/images/design_assets/add-icon.svg';
import profileImg from '../../assets/images/design_assets/profile-icon.svg';
import logoutImg from '../../assets/images/design_assets/logout-icon.svg';
import notloggedinImg from '../../assets/images/design_assets/login2.svg';
import searchImg from '../../assets/images/design_assets/search-icon.svg';
import legalImg from '../../assets/images/design_assets/legal-icon.svg';
import financialImg from '../../assets/images/design_assets/financial-icon.svg';
import environmentalImg from '../../assets/images/design_assets/enviro-icon.svg';
import socialImg from '../../assets/images/design_assets/social-icon.svg';
import pinSmallImg from '../../assets/images/design_assets/pin-icon.svg';
import zoomImg from '../../assets/images/design_assets/zoom-img-icon.svg';
import connectionsImg from '../../assets/images/design_assets/connection-view-entry-icon.svg';
import arrowDownImg from '../../assets/images/design_assets/arrow-down-icon.svg';
import arrowAscImg from '../../assets/images/design_assets/arrow-asc.svg';
import arrowRightImg from '../../assets/images/design_assets/arrow-right-icon.svg';
import marketplaceSettingsImg from '../../assets/images/design_assets/marketplace-settings-icon.svg';
import auctionSettingsImg from '../../assets/images/design_assets/auction-settings-icon.svg';
import matchmakingImg from '../../assets/images/design_assets/matchmaking-icon.svg';
import popularSettingsImg from '../../assets/images/design_assets/popular-settings-icon.svg';
import gridViewActive from '../../assets/images/design_assets/grip-icon-blue.svg';
import mapViewActive from '../../assets/images/design_assets/map-icon-blue.svg';
import gridView from '../../assets/images/design_assets/grip-icon.svg';
import mapView from '../../assets/images/design_assets/map-icon.svg';
import arrowGreyImg from '../../assets/images/design_assets/arrow-icon-grey.svg';
import bgCirclesImg from '../../assets/images/design_assets/bg-circles.svg';
import convertImg from '../../assets/images/design_assets/convertImg.svg';
import accepted from '../../assets/images/design_assets/approved.svg';
import rejected from '../../assets/images/design_assets/Refused.svg';
import pending from '../../assets/images/design_assets/pending.svg';
import signUp from '../../assets/images/design_assets/sign-up-solid.svg';
import landingPageSlideLeaf from '../../assets/images/design_assets/leaf-bg-removed.png';
import facebookIcon from '../../assets/images/design_assets/facebook.svg';
import linkedinIcon from '../../assets/images/design_assets/linkedin.svg';
import closeIcon from '../../assets/images/design_assets/close.svg';
import smallBanner from '../../assets/images/design_assets/small-banner.png';
import businessEff from '../../assets/images/bus_eff.png';
import envirImpact from '../../assets/images/environ_impact.png';
import busEffSmallBanner from '../../assets/images/design_assets/bus_eff_small_banner.png';
import enImSmallBanner from '../../assets/images/design_assets/en_im_small_banner.png';
import shareImg from '../../assets/images/design_assets/share-icon.svg';
import shareImgBlueBG from '../../assets/images/design_assets/share-icon-blue-bg.svg';


const AssetGallery = {
  socialImg,
  emeLogo,
  pinSmallImg,
  zoomImg,
  connectionsImg,
  marketplaceImg,
  catalogueImg,
  infoImg,
  plusImg,
  xImgDark,
  profileImg,
  loginImg,
  notloggedinImg,
  logoutImg,
  clientImg,
  userImg,
  legalImg,
  searchesTag,
  downloadImg,
  financialImg,
  environmentalImg,
  fullScreenImg,
  roleImg,
  entryImg,
  formImg,
  auctionsImg,
  sortByImg,
  filterImg,
  searchImg,
  noImgPlaceholder,
  addAssetImg,
  tagImg,
  xIcon,
  editImg,
  xImg,
  calendarImg,
  eyeImg,
  blackPinImg,
  bluePinImg,
  arrowDownImg,
  arrowAscImg,
  arrowRightImg,
  marketplaceSettingsImg,
  auctionSettingsImg,
  matchmakingImg,
  popularSettingsImg,
  gridView,
  mapView,
  gridViewActive,
  mapViewActive,
  arrowGreyImg,
  bgCirclesImg,
  convertImg,
  accepted,
  rejected,
  pending,
  signUp,
  landingPageSlideLeaf,
  facebookIcon,
  linkedinIcon,
  closeIcon,
  smallBanner,
  businessEff,
  envirImpact,
  busEffSmallBanner,
  enImSmallBanner,
  shareImg,
  shareImgBlueBG,
};

export default AssetGallery;