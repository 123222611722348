import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createSolutionOutputTag, fetchActiveSolutionOutputTag, fetchSolutionOutputTags, updateSolutionOutputTag } from '../../../actions/SolutionOutputTag';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveSolutionOutputTag,
  isPostingSolutionOutputTag,
  postingSolutionOutputTagError,
  postingSolutionOutputTagFailed,
  postingSolutionOutputTagValidationErrors,
  isFetchingSolutionOutputTags,
} from '../../../selectors/SolutionOutputTag';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { getTags } from '../../../selectors/Tag';
import { fetchTags } from '../../../actions/Tag';
import { fetchSolutions } from '../../../actions/Solution';
import { getSolutions } from '../../../selectors/Solution';



const SolutionOutputTagForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeSolutionOutputTag = useSelector(getActiveSolutionOutputTag);
  const tags = useSelector(getTags);
  const solutions = useSelector(getSolutions);
  const isPosting = useSelector(isPostingSolutionOutputTag);
  const isFetching = useSelector(isFetchingSolutionOutputTags);
  const postingFailed = useSelector(postingSolutionOutputTagFailed);
  const postingError = useSelector(postingSolutionOutputTagError);
  const postingValidationErrors = useSelector(postingSolutionOutputTagValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.SOLUTION_OUTPUT_TAG_UPDATE);

  const formConfig = [
    { field: 'solutionId', validators: [isRequired] },
    { field: 'tagId', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [tagId, setOutputTagId] = useState('');
  const [solutionId, setSolutionId] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchSolutions());
    dispatch(fetchTags());
    dispatch(fetchSolutionOutputTags());
    if (editMode) {
      dispatch(fetchActiveSolutionOutputTag(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeSolutionOutputTag && editMode) {
      setSolutionId(activeSolutionOutputTag?.solutionId);
      setOutputTagId(activeSolutionOutputTag?.tagId);
      // @ts-ignore
      setActive(activeSolutionOutputTag?.active === 1 || activeSolutionOutputTag?.active === true);
    }
  }, [activeSolutionOutputTag]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.SOLUTION_OUTPUT_TAG);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);

      dispatch(createSolutionOutputTag(solutionId, tagId, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateSolutionOutputTag(activeId, solutionId, tagId, active));
    }
  };

  const itemsOutputTags = tags?.data.map(r => ({ label: r.value, value: r.id })) || [];
  const itemsSolutions = solutions?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onOutputTagSelect = (obj: any) => {
    setOutputTagId(obj.value);
  };

  const onSolutionSelect = (obj: any) => {
    setSolutionId(obj.value);
  };

  return (
      <View>
        <h4>{editMode ? 'Edit' : 'Create'} SolutionOutputTag</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
              {postingError}
            </Alert>
        )}
        <form>
          <DropDown v={v} err={err}
                    id="solutionId"
                    placeholder="Please select a solution"
                    required label="Solution"
                    items={itemsSolutions}
                    value={solutionId}
                    disabled={isPosting || isFetching}
                    onSelect={onSolutionSelect}
                    type="default"
          />
          <DropDown v={v} err={err}
                    id="tagId"
                    placeholder="Please select a OutputTag"
                    required label="OutputTag"
                    items={itemsOutputTags}
                    value={tagId}
                    disabled={isPosting || isFetching}
                    onSelect={onOutputTagSelect}
                    type="default"
          />
          <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
          <div className="row">
            <div className="col-md-4">
              <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                      onClick={() => editMode ? onSave() : onSubmit()}
                      title={editMode ? 'SAVE' : 'CREATE'}/>
            </div>
          </div>
        </form>
      </View>
  );

};

export default SolutionOutputTagForm;
