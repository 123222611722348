import { ReactNode, useEffect, useState } from 'react';
import React from 'react';
import './DataTable.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Eye, Trash, Pencil, FolderPlus } from 'react-bootstrap-icons';
import DropDown from '../DropDown';
import SearchBar from '../SearchBar';
import Button from '../Button';
import { useDispatch } from 'react-redux';
import { range } from 'lodash';
import { IoMdArrowDropdown } from 'react-icons/io';
import { IoMdArrowDropup } from 'react-icons/io';
import { MdHorizontalRule } from 'react-icons/md';
import { Paging, DataTableParamsModel } from '../../types/Common';
import ButtonToggler from '../ButtonToggler';
import assetGallery from '../AssetGallery';
import AssetGallery from '../AssetGallery';
import Loading from '../Loading';


type Column = {
  label: string;
  format?: (value: any, row: any) => string | ReactNode;
  key: string;
  hidden?: boolean;
  mobileFriendly?: boolean;
  tabletFriendly?: boolean;
  icon?: ReactNode;
};

type Action = {
  label?: string;
  icon: 'edit' | 'bin' | 'eye' | 'convert' | string;
  onClick?: (row: any) => void;
};

type Config = {
  columns: Column[];
  actions: Action[];
  hideControls?: boolean;
  hideOverheadControls?: boolean;
  hideButton?: boolean;
  hasExtraAction?: boolean;
  pageSizes?: number[];
};

type Props = {
  //new params
  fetchFunction?:(searchParam?: DataTableParamsModel) => void ;
  paging?: Paging | never[];
  //pass 'all' as key in baseSearch if you want to search all columns
  baseSearch?: {};
  fetchParams?: {};
  isTable?: boolean;
  lineItemTemplate?: (row: any) => React.ReactNode;
  loading?: boolean;
  error?: boolean;
  data: any[] | undefined;
  config: Config;
  title?: string;
  ctaTitle?: string;
  onCreate?: () => void;
  hasExtraAction?: boolean;
  extraActionTitle?: string;
  extraFunc?: () => void;
  togglerFunc?: () => void;
  gridView?: boolean;
  dependencies?: any;
  isLoading:boolean;
};

const DataTable = (props: Props) => {
  const {
    onCreate = () => {
    }, extraFunc = () => {}, config: { columns = [], actions = [], pageSizes = [10, 20, 30], hideControls = false, hideOverheadControls = false, hideButton = false, hasExtraAction = false }, data = [], title = '', ctaTitle = 'N/A',
    extraActionTitle = 'N/A', fetchFunction, paging, baseSearch = {}, fetchParams = {}, isTable = true, lineItemTemplate, togglerFunc = undefined, dependencies, gridView = true } = props;

  const dispatch = useDispatch();
  const [pages, setPages] = useState([1, 2, 3, 4]);
  const [pageSize, setPageSize] = useState(pageSizes[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchWord, setSearchWord] = useState('');
  const [searchField, setSearchField] = useState('');
  const [sort, setSort] = useState(false);
  const [colKey, setColKey] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    // @ts-ignore
    const newTotalPages = Math.ceil(props.paging?.total / pageSize);
    // @ts-ignore
    setTotal(paging?.total);
    setTotalPages(newTotalPages);
    const maxPagesToShow = 4;
    const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    const endPage = Math.min(startPage + maxPagesToShow - 1, newTotalPages);
    const newPages = Array.from({ length: endPage - startPage + 1 }, (_, i) => i + startPage);
    setPages(newPages);
  }, [paging, pageSize, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [...(Array.isArray(dependencies) ? dependencies : [dependencies])]);


  useEffect(() => {
    if (fetchFunction){
      dispatch(fetchFunction({ searchField:searchField, searchWord: searchWord, baseSearch: baseSearch, fetchParams: fetchParams, colKey: colKey, sortDirection : sortDirection, paging : pageSize.toString(), offset: searchWord ? 1 : currentPage }));
    }
  }, [pageSize, currentPage, searchWord, searchField, colKey, sortDirection, dispatch, ...(Array.isArray(dependencies) ? dependencies : [dependencies])]);


  const renderIcon = (icon: any) => {
    switch (icon) {
      case 'edit': {/* @ts-ignore */
      }
        return <Pencil/>;
      case 'convert': {/* @ts-ignore */
      }
        return <img src={assetGallery.convertImg} alt="convert"/>;
      case 'eye': {/* @ts-ignore */
      }
        return <Eye/>;
      case 'bin': {/* @ts-ignore */
      }
        return <Trash/>;
      case 'add': {/* @ts-ignore */
      }
        return <FolderPlus/>;
      default:
        return '';
    }
  };

  const colClassName = (col: Column) => {
    let finalClassName = 'd-lg-table-cell';
    if (col.mobileFriendly === false) {
      finalClassName = `${finalClassName} d-none d-sm-none d-md-table-cell`;
    }
    if (col.tabletFriendly === false) {
      finalClassName = `${finalClassName} d-md-none`;
    }
    return finalClassName;
  };

  const getIcon = (status: string) => {
    switch (status) {
      case 'Pending':
        return <img src={assetGallery.pending} alt="Pending" />;
      case 'Accepted':
        return <img src={assetGallery.accepted} alt="Accepted" />;
      case 'Rejected':
        return <img src={assetGallery.rejected} alt="Rejected" />;
      default:
        return null;
    }
  };

  const getValue = (row: any, col: Column) => {
    // Check if the column has an icon override
    if (col.icon) {
      return col.icon;
    }

    // Check if the column key corresponds to verification/agreement/transaction/onboarded
    switch (col.key) {
      case 'verification':
        return getIcon(row.verification);
      case 'transaction':
        return getIcon(row.transaction);
      case 'agreement':
        return getIcon(row.agreement);
      case 'onboarded':
        return getIcon(row.onboarded);
      default:
        // Use default format if no specific logic for rendering icons is provided
        return col?.format ? col.format(row[col.key], row) : row[col.key];
    }
  };

  //Short functionalities
  const onShort = (obj:any) =>{
    if (obj !== colKey) {
      setSort(false);
      setColKey(obj);
      setSortDirection('desc');
    } else {
      setSort(!sort);
      setColKey(obj);
      if (!sort){
        setSortDirection('asc');
      } else {setSortDirection('desc');}
    }
  };

  // Change page functionalities
  const ChangePagination = (obj:any) =>{
    setPageSize(obj.value);
    setCurrentPage(1);
  };
  const onChangePage = (p: number, e: any) =>{
    e.preventDefault();
    setCurrentPage(p);
    setPages(range(Math.max(p - 2, 1), Math.min(p + 2, totalPages) + 1));
  };

  const goToFirstPage = (e: any) => {
    onChangePage(1, e);
  };

  // Function to go to the last page
  const goToLastPage = (e: any) => {
    onChangePage(totalPages, e);
  };


  //Search functionalities
  let field = columns?.map(ff => {
    return {
      value: ff.key,
      label: ff.label,
    };
  });

  let fieldArray = columns?.map(ff => ff.key);
  const fieldOptions = [{ value: fieldArray, label: 'All' }, ...field].sort((a, b) => a.label > b.label ? 1 : -1);

  const onSearch = (searchTerm: string, filterTerm: string, sortTerm: string, orderBy: boolean) => {
    setSearchWord(searchTerm);
    setSearchField(filterTerm);
    setColKey(sortTerm);
    setSortDirection(orderBy ? 'asc' : 'desc');
  };

  const renderTable = () => <table className="table table-striped">
    <thead className='thead-dark'>
    <tr>
      {columns.map((h) => (
          <th
              key={h.key}
              scope="col"
              className={colClassName(h)}
          >
            <div className={`column-container ${!sort &&  colKey === h.key ? 'th--active' : ''}`} >
              <div className='table--column-title'>
                {h.label}
              </div>
              <div className='column-image' onClick={()=>onShort(h.key)}>
                {(!sort || sort) && colKey !== h.key  && <MdHorizontalRule size={10}/>}
                {!sort &&  colKey === h.key && <IoMdArrowDropdown/>}
                {sort && colKey === h.key && <IoMdArrowDropup/>}
              </div>
            </div>
          </th>
      ))}
      {(!hideControls && actions.length > 0) && <th></th>}
    </tr>
    </thead>


    <tbody>
    {data?.map((row) => (
        <tr key={`${row.id}-row`} className="">
          {columns.map((col) => (
              <td valign="middle" key={`${row.id}-${col.key}`}
                  className={`d-lg-table-cell ${colClassName(col)}`}>
                {getValue(row, col)}
              </td>
          ))}
          <td valign="middle">
            {!hideControls && <div className="data__table__actions">
              {actions.map((action) => (
                  <div className="data__table__actions__action" key={action.icon}
                       onClick={() => action.onClick?.(row)}>
                    {renderIcon(action.icon)}
                  </div>
              ))}
            </div>}
          </td>
        </tr>
    ))}
    </tbody>
  </table>;

  const renderOther = () => <div className="row justify-content-start">
    {!props.isLoading && data?.map((row) => lineItemTemplate && lineItemTemplate(row))}
  </div>;
  return (
      <>
        <div className="data__table">
          {!hideOverheadControls && <div className="row align-items-center justify-content-between">
            {(!hideOverheadControls && title) && <div className="col-md-5">
              <h4 className='blue-text'>{title}</h4>
            </div>}
            {!hideOverheadControls && <div className="col-9 col-md-10">
              {/* @ts-ignore */}
              <SearchBar fields={fieldOptions} onSearch={onSearch} placeholder={'Search'} defaultFilterField={fieldArray}/>
            </div>}
            {hasExtraAction && <div className="col-md-2"><Button title={extraActionTitle} onClick={extraFunc} /> </div>}
            {!hideOverheadControls && !hideButton && <div className="col-md-2">
              <Button title={ctaTitle} onClick={onCreate}/>
            </div>}
            {togglerFunc && <div className="col-auto mt-md-0 mt-2">
              <ButtonToggler grid={gridView} title1={'Grid View'} title2={'Map View'} img1={gridView ? assetGallery.gridViewActive : assetGallery.gridView} img2={gridView ? assetGallery.mapView : assetGallery.mapViewActive} onClick={togglerFunc}/>
            </div>}
          </div>}
          <div className={`data__table__top ${isTable ? 'other' : ''}`}>
            {isTable ? renderTable() : renderOther()}
            {props.isLoading && <Loading/>}
          </div>

          { data.length > 0 ?
            ( !props.isLoading && <>
                <div className="d-flex justify-content-center justify-content-between paging__footer">
                  <div className="d-sm-flex justify-content-center align-items-center flex-column table__footer__left">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <span className="paging__color-black px-2 d-none d-sm-none d-md-block">Showing:</span>
                      <DropDown
                          placeholder={`${(pageSize > total) ? total : pageSize}`}
                          value={(pageSize > total) ? total : pageSize}
                          radius={true}
                          items={pageSizes.map(s => ({ value: s, label: `${s}` }))} onSelect={ChangePagination} type='default'/>
                      <span className="paging__color-black px-2 d-none d-sm-none d-md-block">out of {total}</span>
                    </div>
                  </div>


                  <div className="paging d-flex justify-content-center align-items-center flex-column table__footer__right">
                    <nav aria-label="...">
                      <ul className="pagination align-items-center p-0 m-0">
                        <li className="paging__page-item page-item">
                          <img src={AssetGallery.arrowGreyImg} alt="Icon Previous" className={`paging__arrow ${currentPage === 1 ? 'd-none' : ''} paging__arrow--left`} onClick={(e)=>onChangePage(currentPage - 1, e)}/>
                          <button className='paging__page-items' onClick={(e) => goToFirstPage(e)}>
                            First
                          </button>
                        </li>
                        {pages.map(page =>
                            <li key={page} className={currentPage === page ? 'paging__li-item-active' : ''}>
                              <button className={currentPage === page ? 'paging__page-items active' : 'paging__page-items'} onClick={(e) => onChangePage(page, e)}>
                                {page}
                              </button>
                            </li>,
                        )}
                        <li className="paging__page-item page-item">
                          <button className='paging__page-items'  onClick={(e) => goToLastPage(e)}>
                            Last
                          </button>
                          <img src={AssetGallery.arrowGreyImg} alt="Icon Next" className={`paging__arrow ${currentPage === totalPages ? 'd-none' : ''}`} onClick={(e)=>onChangePage(currentPage + 1, e)}/>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </>) : (!(props.isLoading) && <div className='paging__no-data'>No Data Found</div>)
          }
        </div>
      </>
  );
};

export default DataTable;
