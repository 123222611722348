import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import LandingPageView from '../../components/LandingPageView';
import DataTable from '../../components/DataTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPopularEntries,
  getRecentlyAddedEntries,
  getRecentlyReusedEntries,
  isFetchingPopularEntries, isFetchingRecentlyAddedEntries, isFetchingRecentlyReusedEntries,
} from '../../selectors/Entry';
import {
  fetchPopularEntries,
  fetchRecentlyAddedEntries,
  fetchRecentlyReusedEntries,
} from '../../actions/Entry';
import c, { EntryStatus } from '../../helpers/constants';
import Card from '../../components/Card/Card';
import { formatUrl, latLngCalculation } from '../../helpers/functions';
import { EntryDataModel } from '../../types/Entry';
import { getPopularSearches } from '../../selectors/PopularSearch';
import ButtonToggler from '../../components/ButtonToggler';
import assetGallery from '../../components/AssetGallery';
import MaterialMap from '../../components/MaterialMap';
import { DataTableParamsModel } from '../../types/Common';
import { fetchPopularSearches } from '../../actions/PopularSearch';


function SearchResults() {
  const dispatch = useDispatch();
  const latestEntries = useSelector(getRecentlyAddedEntries);
  const reusedEntries = useSelector(getRecentlyReusedEntries);
  const popularEntries = useSelector(getPopularEntries);
  const isLoadingRecentlyAdded = useSelector(isFetchingRecentlyAddedEntries);
  const isLoadingRecentlyReused = useSelector(isFetchingRecentlyReusedEntries);
  const isLoadingPopular = useSelector(isFetchingPopularEntries);
  const [publicZoom, setPublicZoom] = useState(5);
  const [publicLat, setPublicLat] = useState(52.1312863);
  const [publicLng, setPublicLng] = useState(-0.0544277);


  const [gridView, setGridView] = useState(true);
  const [toggleSelectable] = useState(false);
  const [selectedEntryIds, setSelectedEntryIds] = useState<string[]>([]);
  const { search } = useLocation();
  const popularSearches = useSelector(getPopularSearches);
  const queryParams: URLSearchParams = new URLSearchParams(search);
  let query = queryParams.get('q');
  let type = queryParams.get('type');
  const [entries, setEntries] = useState<EntryDataModel | undefined>(undefined);
  const [searchTerms, setSearchTerms] = useState(popularSearches?.data.map(searchTerm => searchTerm.searchTerm));
  const history = useHistory();

  useEffect(() => {
    switch (type) {
      case 'recently_added':
        setEntries(latestEntries ?? undefined);
        break;
      case 'recently_reused':
        setEntries(reusedEntries ?? undefined);
        break;
      case 'popular_products':
        setEntries(popularEntries ?? undefined);
        break;
      default:
        setEntries(latestEntries ?? undefined);
    }
  }, [type, latestEntries, reusedEntries, popularEntries]);

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [8, 10, 20, 30],
  };

  const publicStatuses = [
    EntryStatus.PUBLIC,
    EntryStatus.REUSED,
  ];

  const mapPublicItems = entries?.data
    .filter(x => (
      publicStatuses.includes(x.status as EntryStatus) && x.archived == null && x.entryLocation != null
    ))
    .map(e => ({
      'geolocation': e.entryLocation,
      'id': e.id,
      'title': e.title,
      'description': e.description,
      'assetId': e.assetId,
    }));

  useEffect(() => {
    const dataTableParams: DataTableParamsModel = {
      colKey: 'popularity',
      sortDirection: 'desc',
      paging: '10',
    };
    dispatch(fetchPopularSearches(dataTableParams));
  }, [dispatch]);


  // Set default lat lng and zoom based on the first card, also reset this value when the user is clicking in another view
  useEffect(() => {
    if (mapPublicItems) {

      const mapCalc = latLngCalculation(mapPublicItems, mapPublicItems);
      setPublicLat(mapCalc.publicLat);

      setPublicLng(mapCalc.publicLng);
    }
    setPublicZoom(5);
  }, [gridView]);

  const activeCardLocation = (geo: any) => {
    if (geo) {
      setPublicLat(parseFloat(geo.split('~')[0]));
      setPublicLng(parseFloat(geo.split('~')[1]));
      setPublicZoom(8);
    }
  };

  useEffect(() => {
    if (popularSearches){
      setSearchTerms(popularSearches?.data.map(searchTerm => searchTerm.searchTerm));
    }
  }, [popularSearches]);

  const handleCardSelect = (entryId: string) => {
    if (selectedEntryIds.includes(entryId)) {
      setSelectedEntryIds(selectedEntryIds.filter(id => id !== entryId));
    } else {
      setSelectedEntryIds([...selectedEntryIds, entryId]);
    }
  };

  const onNavigate = (entryId: string) => {
    history.push(formatUrl(c.APP_ROUTES.ENTRY_RENDERER_PUBLIC, entryId));
  };

  const getTitle = (t: any) => {
    if (t == 'recently_added'){
      return 'RECENTLY ADDED';
    } else if (t == 'recently_reused'){
      return 'RECENTLY REUSED ITEMS';
    } else if (t == 'popular_products'){
      return 'POPULAR';
    } else {
      return '';
    }
  };

  const renderDataTable = (items: EntryDataModel | null, isLoading: boolean, viewGrid: boolean, setViewGrid: React.Dispatch<React.SetStateAction<boolean>>) => {
    return (
        <>
          {viewGrid && <DataTable
              paging={items?.paging}
              isLoading={isLoading}
              fetchFunction={type === 'recently_added' || query ? fetchRecentlyAddedEntries :
                type === 'recently_reused' ? fetchRecentlyReusedEntries :
                  (type === 'popular_products' && popularSearches) ? fetchPopularEntries : undefined}
              fetchParams={{ 'visibilityPublic': true }}
              baseSearch={type === 'recently_added' ? { 'status': 'Public' } :
                type === 'recently_reused' ? { 'status': 'Reused' } :
                  (type === 'popular_products' && searchTerms && searchTerms.length > 0) ? {
                    'all': searchTerms?.slice(0, 3),
                    'status': ['Public', 'Reused'],
                  } : query ? { 'all': query, 'status': ['Public', 'Reused'] } : undefined}
              data={items?.data}
              dependencies={[query, type, popularSearches, searchTerms]}
              config={config}
              isTable={false}
              gridView={viewGrid}
              togglerFunc={mapPublicItems && mapPublicItems.length > 0 ? () => setViewGrid((prev) => !prev) : undefined}
              lineItemTemplate={(row: any) => (
                  <div className={'col-lg-3 col-md-6 col-sm-12 mb-4'}>
                    <Card
                        onClick={() => onNavigate(row.id)}
                        cardId={row.index}
                        title={row.title}
                        description={row.description}
                        itemId={row.id}
                        imageUrl={`${c.API_ENDPOINTS.ASSET_FILE}/${row.assetId}`}
                        selectable={toggleSelectable}
                        onSelect={handleCardSelect}
                    />
                  </div>
              )}
          />}
          {!viewGrid &&
              <div className="col-12">
                <div className="view_map">
                  <div className="d-flex flex-column align-items-end mb-3">
                    <div className='view__map-grid--size'>
                      <ButtonToggler grid={gridView} title1={'Grid View'} title2={'Map View'} img1={gridView ? assetGallery.gridViewActive : assetGallery.gridView} img2={gridView ? assetGallery.mapView : assetGallery.mapViewActive} onClick={()=>setGridView(!gridView)}/>
                    </div>
                  </div>
                  <MaterialMap
                      lat={publicLat}
                      lng={publicLng}
                      view={'viewRenderer'}
                      zoom={publicZoom}
                      mapList={mapPublicItems}
                      data ={items?.data}
                      enableInfoWindow={true}
                      activeCardLocation={activeCardLocation}
                  />
                </div>
              </div>
          }
        </>
        
    );
  };

  return (
        <LandingPageView popularSearches={popularSearches}>
            <div className="m-4 min-vh-100">
              <h2 className="my-3">{getTitle(type)}</h2>
              {type && <>
                {type === 'recently_added' &&
                    <>
                      {renderDataTable(latestEntries, isLoadingRecentlyAdded, gridView, setGridView)}
                    </>
                  }
                {type === 'recently_reused' &&
                    <>
                      {renderDataTable(reusedEntries, isLoadingRecentlyReused, gridView, setGridView)}
                    </>
                }
                {type === 'popular_products' &&
                    <>
                      {renderDataTable(popularEntries, isLoadingPopular, gridView, setGridView)}
                    </>
                }
                </>
                }
              {query &&
              <>
              {renderDataTable(latestEntries, isLoadingRecentlyAdded, gridView, setGridView)}
              </>
              }
            </div>
        </LandingPageView>
  );
}

export default SearchResults;