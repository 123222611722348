import {
  FETCH_AUCTION_FAILURE,
  FETCH_AUCTION_REQUEST,
  FETCH_AUCTION_RESPONSE,
  FETCH_AUCTIONS_FAILURE,
  FETCH_AUCTIONS_REQUEST,
  FETCH_AUCTIONS_RESPONSE,
  POST_AUCTION_FAILURE,
  POST_AUCTION_RESPONSE,
  AuctionActionTypes,
  AuctionState,
  POST_AUCTION_REQUEST,
} from '../types/Auction';
import { parseValidationErrors } from '../helpers/functions';

const initialState: AuctionState = {
  auctions: null,
  activeAuction: null,
  isFetchingAuctions: false,
  fetchingAuctionsFailed: false,
  isPostingAuction: false,
  postingAuctionFailed: false,
  postingAuctionError: null,
  postingAuctionValidationErrors: null,
};

const auctionsReducer = (
  state = initialState,
  action: AuctionActionTypes,
): AuctionState => {
  switch (action.type) {
    case FETCH_AUCTIONS_REQUEST:
      return {
        ...state,
        auctions: null,
        isFetchingAuctions: true,
        fetchingAuctionsFailed: false,
      };
    case FETCH_AUCTIONS_RESPONSE:
      return {
        ...state,
        auctions: action.payload || [],
        isFetchingAuctions: false,
        fetchingAuctionsFailed: false,
      };
    case FETCH_AUCTIONS_FAILURE:
      return {
        ...state,
        auctions: null,
        isFetchingAuctions: false,
        fetchingAuctionsFailed: true,
      };
    case FETCH_AUCTION_REQUEST:
      return {
        ...state,
        auctions: null,
        isFetchingAuctions: true,
        fetchingAuctionsFailed: false,
      };
    case FETCH_AUCTION_RESPONSE:
      return {
        ...state,
        activeAuction: action.payload,
        isFetchingAuctions: false,
        fetchingAuctionsFailed: false,
      };
    case FETCH_AUCTION_FAILURE:
      return {
        ...state,
        auctions: null,
        isFetchingAuctions: false,
        fetchingAuctionsFailed: true,
      };
    case POST_AUCTION_REQUEST:
      return {
        ...state,
        isPostingAuction: true,
        postingAuctionError: null,
        postingAuctionValidationErrors: null,
        postingAuctionFailed: false,
      };
    case POST_AUCTION_RESPONSE:
      return {
        ...state,
        isPostingAuction: false,
        postingAuctionError: null,
        postingAuctionValidationErrors: null,
        postingAuctionFailed: false,
      };
    case POST_AUCTION_FAILURE:
      return {
        ...state,
        isPostingAuction: false,
        postingAuctionError: action.payload.error || 'An error occurred',
        postingAuctionValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingAuctionFailed: true,
      };
    default:
      return state;
  }
};

export default auctionsReducer;
