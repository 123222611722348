import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import './DateSelect.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { Validator } from '../../hooks/ValidationHook';
import gallery from '../AssetGallery/AssetGallery';
import { format } from 'date-fns';


type Props = {
  title?: string;
  onChange?: (e: any) => void;
  value?: Date | null | undefined;
  minDateTime?: Date | null | undefined;
  required?: boolean;
  disabled?: boolean;
  v?: any;
  err?: (id: string) => string[];
  validate?: any,
  validators?: Validator[]
  errors?: string[];
  id?: string;
};

const DateSelect = (props:Props) => {
  
  const {
    title = '',
    onChange = () => { },
    disabled = false,
    required = false,
    value = null,
    minDateTime = null,
    err = () => [],
    v = () => {},
    id = '',
  } = props;

  const [touched, setTouched] = useState(false);
  
  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);
  
  const innerOnChange = (e:any) =>{
    setTouched(true);
    onChange(e);
    if (v && typeof v === 'function') {
      v(id, e);
    }
  };
  const errors = err(id);

  const CustomInput = ({  onClick }: any) => (
      <div className="custom-datepicker-input" onClick={onClick}>
        <img src={gallery.calendarImg} alt="" className='calendar-icon' />
        {value ? <span>{format(value, 'yyyy-MM-dd HH:mm:ss')?.toString()}</span> :
            <span>Select Date</span>
        }
      </div>
  );

  return (
    <div className="date-picker">
      {title && <div className="mb-2 label">{title}</div>}
      <DatePicker 
          required={required}
          disabled={disabled}
          selected={value}
          onChange={innerOnChange} 
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          customInput={<CustomInput />}
          placeholderText='Select A date'
          dateFormat="yyyy-MM-dd HH:mm"
          minDate={minDateTime}
        />
      {errors.map((e: string, i: number) => (<div className="form-label error-lbl" key={i}>{e}</div>))}
    </div>
  );
};

export default DateSelect;
