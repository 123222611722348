import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import './FileUpload.scss';
import { File } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { createAsset } from '../../actions/Asset';
import FileForm from './FileForm';
import { isPostingAsset } from '../../selectors/Asset';
import { getLoggedInUser } from '../../selectors/Auth';
import Button from '../Button';
import assetGallery from '../AssetGallery/AssetGallery';
import { getActiveConfiguration } from '../../selectors/Configuration';
import { useMobile } from '../../helpers/functions';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type Props = {
  label?: string;
  groupId: string;
  clientId: string;
  type: 'images' | 'files' | 'custom';
  isPrivate?: boolean;
  fileTypes?: string[];
  dynamicId? : string;
};

const FileUpload = (props: Props) => {
  const [filePreviews, setFilePreviews] = useState<any[]>([]);
  const activeConfig = useSelector(getActiveConfiguration);
  const isMobile = useMobile();
  const { label, groupId, clientId } = props;
  const fallbackSize = 10 * 1024 * 1024;
  const maxUploadSize = activeConfig?.maxUploadSize ?? fallbackSize;
  const allowedTypes: string[] = [];
  if (props.type === 'images') {
    allowedTypes.push('image/png', 'image/svg', 'image/svg+xml', 'image/jpeg', 'image/jpg', 'image/webp');
  } else if (props.type === 'files') {
    allowedTypes.push(
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.oasis.opendocument.presentation',
      'application/vnd.oasis.opendocument.spreadsheet',
      'application/vnd.oasis.opendocument.text',
    );
  } else if (props.type === 'custom' && props.fileTypes) {
    allowedTypes.push(...props.fileTypes);
  }

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [indexSubmitted, setIndexSubmitted] = useState(-1);
  const [errors, setErrors] = useState<any[]>([]);

  const isPosting = useSelector(isPostingAsset);

  const dispatch = useDispatch();
  const loggedInUser = useSelector(getLoggedInUser);

  const userId = props.isPrivate ? (loggedInUser?.id || '') : '';


  const onSubmit = (file: any, title: string, index: number) => {
    setHasSubmitted(true);
    setIndexSubmitted(index);
    const finalFormData = new FormData();
    finalFormData.append('assets[]', file);
    dispatch(createAsset(groupId, clientId, userId, title, finalFormData, props.dynamicId ));
  };

  const onDrop = useCallback((acceptedFiles) => {
    setErrors([]);
    setFilePreviews(
      acceptedFiles.map((file: File) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          accepted: true,
        }),
      ),
    );
  }, []);

  useEffect(()=>{
    filePreviews.map((file: File) => {
      if (props.type === 'images' && file.size > maxUploadSize){
        setErrors((oldErrors) => [...oldErrors, `"${file.name}" exceeds upload limit (${maxUploadSize / (1024 * 1024)}MB).`]);
        Object.assign(file, {
          accepted: false,
        });
      } else if (props.type === 'files' && file.size > maxUploadSize){
        setErrors((oldErrors) => [...oldErrors, `"${file.name}" exceeds upload limit (${maxUploadSize / (1024 * 1024)}MB).`]);
        Object.assign(file, {
          accepted: false,
        });
      }
    });
  }, [filePreviews]);

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    rejectedFiles.map((rejected: FileRejection) => {
      const acceptedTypesMessage = props.fileTypes?.map(type => `.${type.split('/')[1]}`).join(', ');

      if (props.type === 'images' && !allowedTypes.includes(rejected.file.type)) {
        setErrors((oldErrors) => [
          ...oldErrors,
          `"${rejected.file.name}" type rejected. Accepted file types for this field are: .png, .jpg, .jpeg, .svg, .webp`,
        ]);
      } else if (props.type === 'files' && !allowedTypes.includes(rejected.file.type)) {
        setErrors((oldErrors) => [
          ...oldErrors,
          `"${rejected.file.name}" type rejected. Accepted file types for this field are: .pdf, .doc, .docx, .odp, .ods, .odt, .ppt, .pptx, .txt, .xls, .xlsx`,
        ]);
      } else if (props.type === 'custom' && !allowedTypes.includes(rejected.file.type)) {
        setErrors((oldErrors) => [
          ...oldErrors,
          `"${rejected.file.name}" type rejected. Accepted file types for this field are: ${acceptedTypesMessage || ''}`,
        ]);
      }
    });
  };

  const { getRootProps : getMobileRootProps, getInputProps : getMobileInputProps,  isFocused : isMobileFocused,
    isDragAccept : isMobileDragAccept,
    isDragReject : isMobileDragReject, open : openMobile } = useDropzone({
    onDrop, onDropRejected,
    accept:{
      allowedTypes,
    },
    noClick : true,
  });

  const { getRootProps, getInputProps,  isFocused,
    isDragAccept,
    isDragReject, open } = useDropzone({
    onDrop, onDropRejected,
    accept:{
      allowedTypes,
    },
    noClick : true,
  });

  const onDeleteImage = (i: number) => {
    const newFiles = [...filePreviews];
    newFiles.splice(i, 1);
    setFilePreviews(newFiles);
  };

  useEffect(() => {

    if (!isPosting && hasSubmitted) {
      onDeleteImage(indexSubmitted);
      setHasSubmitted(false);
      setIndexSubmitted(-1);
    }
  }, [isPosting]);

  const renderFiles = () => {
    // Filter out rejected files
    const validFiles = filePreviews.filter(file => file.accepted);

    return validFiles.map((file, i) => (
        <div key={file.name} className="col-md-6">
          <FileForm key={file.preview} isValid={file.accepted} file={file} index={i} onSubmit={onSubmit} onDelete={onDeleteImage} />
        </div>
    ));
  };


  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  const styleMobile = useMemo(() => ({
    ...baseStyle,
    ...(isMobileFocused ? focusedStyle : {}),
    ...(isMobileDragAccept ? acceptStyle : {}),
    ...(isMobileDragReject ? rejectStyle : {}),
  }), [
    isMobileFocused,
    isMobileDragAccept,
    isMobileDragReject,
  ]);

  return (
      <>
        <div className="file-upload">
          {label && <label className="form-label">{label}</label>}
          <div>
            {isMobile ?
            <div className="mobile-upload">
              <div {...getMobileRootProps({ style: styleMobile })} onClick={openMobile}>
                <input {...getMobileInputProps()} capture={'environment'}/>
                {errors.length > 0 && (
                    <p className="error-lbl">
                      {errors.map((e, index) => (
                          <li key={index}>{e}</li>
                      ))}
                    </p>
                )}
                    <Button title="Tap To Take Photo From Camera"/>
              </div>
              <div {...getRootProps({ style })} onClick={open}>
                <input {...getInputProps()} />
                {errors.length > 0 && (
                    <p className="error-lbl">
                      {errors.map((e, index) => (
                          <li key={index}>{e}</li>
                      ))}
                    </p>
                )}
                <div className="file-upload__upload-prompt">
                  <Button title={props.type === 'files' ? 'Tap To Upload A File' : 'Tap To Upload An Image From Gallery'}/>
                  <img className="file-upload__upload-prompt__icon" src={assetGallery.addAssetImg}
                       alt="Upload icon"/>
                  {props.type === 'images'
                    ? ' .png, .jpg, .jpeg, .svg, .webp'
                    : props.type === 'files'
                      ? ' .pdf, .doc, .docx, .odp, .ods, .odt, .ppt, .pptx, .txt, .xls, .xlsx'
                      : props.type === 'custom' && props.fileTypes
                        ? ` ${props.fileTypes.map((type) => `.${type.split('/').pop()}`).join(', ')}`
                        : 'all files'}
                </div>
              </div>
            </div>
              :
              (  
         
            <div {...getRootProps({ style })} onClick={open}>
            <input {...getInputProps()}/>
          {errors.length > 0 && (
            <p className="error-lbl">
          {errors.map((e, index) => (
            <li key={index}>{e}</li>
          ))}
            </p>
          )}
            <div className="file-upload__upload-prompt">
            Drag and drop some files here, or
            <Button title={props.type === 'files' ? 'Click to Upload a File' : 'Click to Upload an Image'} />
            <img className="file-upload__upload-prompt__icon" src={assetGallery.addAssetImg} alt="Upload icon" />
          {props.type === 'images'
            ? ' .png, .jpg, .jpeg, .svg, .webp'
            : props.type === 'files'
              ? ' .pdf, .doc, .docx, .odp, .ods, .odt, .ppt, .pptx, .txt, .xls, .xlsx'
              : props.type === 'custom' && props.fileTypes
                ? ` ${props.fileTypes.map((type) => `.${type.split('/').pop()}`).join(', ')}`
                : 'all files'}
            </div>
            </div>)
            }
          </div>
        </div>
  
        {filePreviews.length > 0 && <div className="row">{renderFiles()}</div>}
      </>
  );
};

export default FileUpload;
