import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createSolution, fetchActiveSolution, fetchSolutions, updateSolution } from '../../../actions/Solution';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveSolution,
  isPostingSolution,
  postingSolutionError,
  postingSolutionFailed,
  postingSolutionValidationErrors,
  isFetchingSolutions,
} from '../../../selectors/Solution';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import MaterialMap from '../../../components/MaterialMap';


const SolutionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeSolution = useSelector(getActiveSolution);
  const isPosting = useSelector(isPostingSolution);
  const isFetching = useSelector(isFetchingSolutions);
  const postingFailed = useSelector(postingSolutionFailed);
  const postingError = useSelector(postingSolutionError);
  const postingValidationErrors = useSelector(postingSolutionValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.SOLUTION_UPDATE);

  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPerson, setContactPerson] = useState('');

  const [active, setActive] = useState(true);



  useEffect(() => {
    dispatch(fetchSolutions());
    if (editMode) {
      dispatch(fetchActiveSolution(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeSolution && editMode) {
      setTitle(activeSolution?.title);
      setDescription(activeSolution?.description);
      setLocation(activeSolution?.location);
      // @ts-ignore
      setActive(activeSolution?.active === 1 || activeSolution?.active === true);
    }
  }, [activeSolution]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.SOLUTION);
    }
  }, [isPosting]);

  const onSubmit = async () => {
    if (triggerValidation()) {
      setHasSubmitted(true);

      dispatch(createSolution(title, description, location, contactNumber, contactEmail, contactPerson, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateSolution(activeId, title, description, location, contactNumber, contactEmail, contactPerson, active));
    }
  };

  const onLocation = (value: string) => {
    setLocation(value);
  };

  return (
        <View>
            <h4>{editMode ? 'Edit' : 'Create'} Solution</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <Input v={v} err={err}
                       name="title"
                       id="title"
                       label="Title"
                       required
                       value={title}
                       onChange={setTitle}
                       disabled={isPosting || isFetching}
                       onBlur={setTitle}
                       placeholder="Insert a title"
                />
              <Input v={v} err={err}
                     name="description"
                     id="description"
                     label="Description"
                     required
                     value={description}
                     onChange={setDescription}
                     disabled={isPosting || isFetching}
                     onBlur={setDescription}
                     placeholder="Insert a description"
              />
              <div className="mb-5 pb-3" style={{ height: '600px' }}>
              <label className="form-label">{'Location'}</label>
              <MaterialMap
                  lat={50}
                  lng={-2}
                  zoom={6}
                  address={'London'}
                  onChange={(la, ln, zm, adr) =>
                    onLocation(`${la}~${ln}~${zm}~${adr}`)}
                  editMode />
            </div>
              <Input v={v} err={err}
                     name="contactNumber"
                     id="contactNumber"
                     label="Contact Number"
                     value={contactNumber}
                     onChange={setContactNumber}
                     disabled={isPosting || isFetching}
                     onBlur={setContactNumber}
                     placeholder="Insert a contact number"
              />
              <Input v={v} err={err}
                     name="contactEmail"
                     id="contactEmail"
                     label="Contact Email"
                     value={contactEmail}
                     onChange={setContactEmail}
                     disabled={isPosting || isFetching}
                     onBlur={setContactEmail}
                     placeholder="Insert a contact email"
              />
              <Input v={v} err={err}
                     name="contactPerson"
                     id="contactPerson"
                     label="Contact Person"
                     value={contactPerson}
                     onChange={setContactPerson}
                     disabled={isPosting || isFetching}
                     onBlur={setContactPerson}
                     placeholder="Specify a contact person"
              />
                <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => editMode ? onSave() : onSubmit()}
                                title={editMode ? 'SAVE' : 'CREATE'}/>
                    </div>
                </div>
            </form>
        </View>
  );

};

export default SolutionForm;
