import React, { useEffect, useState } from 'react';
import './Input.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Validator } from '../../hooks/ValidationHook';
import gallery from '../AssetGallery/AssetGallery';


type Props = {
  value?: string | number | null;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  name?: string;
  id?: string;
  label?: string;
  required?: boolean;
  subtitle?: string;
  type?: 'number' | 'text' | 'password' | 'tel';
  bgColor?: 'grey' | 'white';
  style?: 'rounded' | 'normal';
  disabled?: boolean;
  v?: any;
  err?: (id: string) => string[];
  validate?: any,
  validators?: Validator[]
  errors?: string[];
  maxLength?: number;
  autoFocus?: boolean;
};


const Input = (props: Props) => {
  const {
    onBlur = () => {
    },
    onChange = () => {
    },
    onKeyDown = () => {
    },
    disabled = false,
    autoFocus = false,
    required = false,
    placeholder = '',
    value = '',
    name = '',
    subtitle = '',
    id = '',
    label = '',
    err = () => [],
    bgColor = 'grey',
    type = 'text',
    v = () => {
    },
    maxLength,
  } = props;

  const [touched, setTouched] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);


  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);

  const innerOnChange = (e: any) => {
    const inputValue = e.target.value;
    if (type === 'tel') {
      if (/^[+0-9 ]*$/.test(inputValue)) {
        setTouched(true);
        onChange(inputValue);
        if (v && typeof v === 'function') {
          v(id, inputValue);
        }
      }
    } else {
      setTouched(true);
      onChange(inputValue);
      if (v && typeof v === 'function') {
        v(id, inputValue);
      }
    }
  };
  const innerOnBlur = (e: any) => {
    const inputValue = e.target.value;
    if (type === 'tel') {
      if (/^[+0-9 ]*$/.test(inputValue)) {
        setTouched(true);
        onBlur(inputValue);
        if (v && typeof v === 'function') {
          v(id, inputValue);
        }
      }
    } else {
      setTouched(true);
      onBlur(inputValue);
      if (v && typeof v === 'function') {
        v(id, inputValue);
      }
    }
  };


  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  const errors = err(id);

  return (
      <div className={`input ${subtitle ? 'my-3' : 'my-1'}`}>
            {label && <label htmlFor={id} className='form-label'>{label}<span
                className={`form-label ${errors.length > 0 && 'error-lbl'}`}>{required && '*'}</span></label>}
            <div className='row'>
                <div className='custom-input mb-2'>
                    <input
                        disabled={disabled}
                        type={type === 'text' || type === 'number' ||  type === 'tel' ? type : (passwordShown ? 'text' : 'password')}
                        required={required}
                        className={`form-control ${bgColor} ${errors.length > 0 && 'error'}` }
                        value={value || ''} name={name} id={id}
                        onBlur={innerOnBlur}
                        onChange={innerOnChange}
                        onKeyDown={onKeyDown} // pass the onKeyDown prop to the input
                        placeholder={placeholder}
                        maxLength={maxLength}
                        autoFocus={autoFocus}
                    />
                    {subtitle && <small id={`${id}help`} className={subtitle.length > 70 ? 'position-static form-text text-muted subtitle' : 'form-text text-muted subtitle'}>{subtitle}</small>}
                    {errors.length > 0 && <img src={gallery.xImg} alt="" className='error-icon'/>}
                    {type === 'password' && <img src={gallery.eyeImg} alt="" className='input-eye' onClick={togglePassword}/>}
                </div>

                {errors.map((e: string, i: number) => (
                    <div key={i} className='col-auto error-container'>
                        {e}
                    </div>
                ))}
            </div>
        </div>

  );
};

export default Input;
