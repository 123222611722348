import {
  FETCH_MARKETPLACE_FAILURE,
  FETCH_MARKETPLACE_REQUEST,
  FETCH_MARKETPLACE_RESPONSE,
  FETCH_MARKETPLACES_FAILURE,
  FETCH_MARKETPLACES_REQUEST,
  FETCH_MARKETPLACES_RESPONSE,
  POST_MARKETPLACE_FAILURE,
  POST_MARKETPLACE_RESPONSE,
  MarketplaceActionTypes,
  MarketplaceState,
  POST_MARKETPLACE_REQUEST, FETCH_MARKETPLACES_DATATABLE_RESPONSE,
} from '../types/Marketplace';
import { parseValidationErrors } from '../helpers/functions';

const initialState: MarketplaceState = {
  marketplaces: null,
  marketplacesDatatable: null,
  activeMarketplace: null,
  isFetchingMarketplaces: false,
  fetchingMarketplacesFailed: false,
  isPostingMarketplace: false,
  postingMarketplaceFailed: false,
  postingMarketplaceError: null,
  postingMarketplaceValidationErrors: null,
};

const marketplacesReducer = (
  state = initialState,
  action: MarketplaceActionTypes,
): MarketplaceState => {
  switch (action.type) {
    case FETCH_MARKETPLACES_REQUEST:
      return {
        ...state,
        marketplaces: null,
        isFetchingMarketplaces: true,
        fetchingMarketplacesFailed: false,
      };
    case FETCH_MARKETPLACES_RESPONSE:
      return {
        ...state,
        marketplaces: action.payload || [],
        isFetchingMarketplaces: false,
        fetchingMarketplacesFailed: false,
      };
    case FETCH_MARKETPLACES_DATATABLE_RESPONSE:
      return {
        ...state,
        marketplacesDatatable: action.payload || [],
        isFetchingMarketplaces: false,
        fetchingMarketplacesFailed: false,
      };
    case FETCH_MARKETPLACES_FAILURE:
      return {
        ...state,
        marketplaces: null,
        isFetchingMarketplaces: false,
        fetchingMarketplacesFailed: true,
      };
    case FETCH_MARKETPLACE_REQUEST:
      return {
        ...state,
        marketplaces: null,
        isFetchingMarketplaces: true,
        fetchingMarketplacesFailed: false,
      };
    case FETCH_MARKETPLACE_RESPONSE:
      return {
        ...state,
        activeMarketplace: action.payload,
        isFetchingMarketplaces: false,
        fetchingMarketplacesFailed: false,
      };
    case FETCH_MARKETPLACE_FAILURE:
      return {
        ...state,
        marketplaces: null,
        isFetchingMarketplaces: false,
        fetchingMarketplacesFailed: true,
      };
    case POST_MARKETPLACE_REQUEST:
      return {
        ...state,
        isPostingMarketplace: true,
        postingMarketplaceError: null,
        postingMarketplaceValidationErrors: null,
        postingMarketplaceFailed: false,
      };
    case POST_MARKETPLACE_RESPONSE:
      return {
        ...state,
        isPostingMarketplace: false,
        postingMarketplaceError: null,
        postingMarketplaceValidationErrors: null,
        postingMarketplaceFailed: false,
      };
    case POST_MARKETPLACE_FAILURE:
      return {
        ...state,
        isPostingMarketplace: false,
        postingMarketplaceError: action.payload.error || 'An error occurred',
        postingMarketplaceValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingMarketplaceFailed: true,
      };
    default:
      return state;
  }
};

export default marketplacesReducer;
