import React, { useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useSelector } from 'react-redux';
import { getRoles, isFetchingRoles } from '../../../selectors/Role';
import { fetchRoles, storeRole } from '../../../actions/Role';
import { Role } from '../../../types/Role';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const RoleDataTable = () => {

  const roles = useSelector(getRoles);
  const isLoading = useSelector(isFetchingRoles);
  const [toggle, setToggle] = useState(false);
  const [role, setActiveRole] = useState<Role | null>();
  const [refetch, setRefetch] = useState(false);


  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };


  const onCreate = () => {
    history.push(c.APP_ROUTES.ROLE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ROLE_UPDATE, row.id));
  };

  const onArchive = (row: Role) => {
    setActiveRole(row);
    setToggle(true);
  };

  const onConfirmArchive = async () => {
    setToggle(false);
    if (role?.id) {
      await storeRole(role?.id);
      setRefetch(!refetch);
    }
    setActiveRole(null);
  };

  const config = {
    columns: [
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'accessLevel', label: 'Level Of Access', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onArchive }],
    hideButton: true,
  };

  return <View title="Roles" onAddNew={onCreate}>
    <>
      <DataTable fetchFunction={fetchRoles} paging={roles?.paging || []}
                 isLoading={isLoading} data={roles?.data || []} config={config} dependencies={refetch} />
      <Modal show={toggle} title="Archive Role" cta="Archive" onCta={onConfirmArchive} onHide={onDismiss}>
        Are you sure you want to archive this role?
      </Modal>
    </>
  </View>;
};

export default RoleDataTable;
