import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AssetGallery from '../AssetGallery';
import './LandingPageView.scss';
import c from '../../helpers/constants';
import Loading from '../Loading';
import ImageButton from '../ImageButton';
import SearchBoxPublic from '../SearchBoxPublic/SearchBoxPublic';
import { isFetchingPopularEntries } from '../../selectors/Entry';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../actions/Auth';
import { getLoggedInUser } from '../../selectors/Auth';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  searchQuery?: string;
  showFooter?: boolean;
  popularSearches? : any;
};

const LandingPageView = (props: Props) => {
  const { children, isLoading, popularSearches, showFooter = true } = props;
  const history = useHistory();
  const { search } = useLocation();
  const queryParams: URLSearchParams = new URLSearchParams(search);
  let query = queryParams.get('q');
  const isLoadingPopularEntries = useSelector(isFetchingPopularEntries);

  const loggedInUser = useSelector(getLoggedInUser);
  const dispatch = useDispatch();
  const onLogin = () => {
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  const onLogout = () => {
    dispatch(logoutUser());
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  const onSignUp = () => {
    history.push(c.APP_ROUTES.USER_REGISTER);
  };

  const onSearch = (val: string) => {
    history.push(c.APP_ROUTES.SEARCH_RESULT + '?q=' + val);
  };

  const handleLogoClick = () => {
    history.push('/');
  };

  return (<>
                    <div className='d-flex flex-column min-vh-100 landing-page-bg'>
                        <div className='pt-2 bg-white d-md-none d-block'>
                            <div className='d-flex pt-2 pe-2 ps-2'>
                                <div className='p-2 flex-grow-1 view-landing-page__sidebar__logo__container' onClick={handleLogoClick}>
                                    <img src={AssetGallery.emeLogo} alt="Logo" className='view-landing-page__sidebar__logo__img'/>
                                </div>
                                {!loggedInUser ? <>
                                    <div className="p-2">
                                        <ImageButton iconSrc={AssetGallery.signUp} label="Sign Up"
                                                     onClick={onSignUp}/>
                                    </div>
                                    <div className="p-2">
                                        <ImageButton iconSrc={AssetGallery.notloggedinImg} label="Login"
                                                     onClick={onLogin}/>
                                    </div>
                                </> :
                                    <div className="p-2">
                                        <ImageButton iconSrc={AssetGallery.logoutImg} label="Log out"
                                                     onClick={onLogout}/>
                                    </div>
                                }
                            </div>
                            <SearchBoxPublic customStyle={{ backgroundColor: '#d1e9f4' }}
                                             popularSearches={popularSearches?.data}
                                             searchQuery={query ? query : ''} onSearch={onSearch}/>
                        </div>
                        <div className='d-none d-lg-flex d-md-flex justify-content-between bg-white'>
                            <div className='pt-3'>
                                <div
                                    className="d-flex align-items-center mb-4 view-landing-page__sidebar__logo__container"
                                     onClick={handleLogoClick}>
                                    <img src={AssetGallery.emeLogo} alt="Logo"
                                         className="view-landing-page__sidebar__logo__img"/>
                                </div>
                            </div>
                            <div className='flex-fill px-1'>
                                <SearchBoxPublic customStyle={{ backgroundColor: '#d1e9f4' }}
                                                 popularSearches={popularSearches?.data}
                                                 isLoadingPopular = {isLoadingPopularEntries}
                                                 searchQuery={query ? query : ''} onSearch={onSearch}/>
                            </div>
                            {!loggedInUser ? <>
                                <div className="pt-3 ps-2 pe-2">
                                    <ImageButton iconSrc={AssetGallery.signUp} label="Sign Up"
                                                 onClick={onSignUp}/>
                                </div>
                                <div className='pt-3 ps-md-1 pe-3'>
                                    <><ImageButton iconSrc={AssetGallery.notloggedinImg} label="Login"
                                                   onClick={onLogin}/>
                                    </>
                                </div>
                                </> :
                            <div className="pt-3 ps-s pe-2">
                                <ImageButton iconSrc={AssetGallery.logoutImg} label="Log out"
                                             onClick={onLogout}/>
                            </div>
                                }
                        </div>
                        {isLoading && <Loading/>}
                        <div className={isLoading ? 'mx-4 view-landing-page__loading' : '' }>{children}</div>
                        {(!isLoading && showFooter) &&
                            <>
                                <div className="container-fluid bg-white shadow-lg footer text-muted">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex justify-content-start">
                                            <div className="p-2">&copy;2024 EME. All Rights Reserved</div>
                                            <div className="p-2"><a href="#">Privacy Policy</a></div>
                                            <div className="p-2">
                                                <a href={`${c.APP_ROUTES.CORE}/ExcessMaterialsExchange-Terms-Conditions.pdf`}>Terms and Conditions</a>
                                            </div>
                                            <div className="p-2"><a href="mailto:support@excessmaterialsexchange.com">Contact Support</a></div>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div className="p-2"><a
                                                href="https://www.facebook.com/excessmaterialsexchange/" target="_blank"
                                                rel="noreferrer"><img src={AssetGallery.facebookIcon} alt={'Facebook'}/></a>
                                            </div>
                                            <div className="p-2"><a
                                                href="https://www.linkedin.com/company/excess-materials-exchange/"
                                                target="_blank" rel="noreferrer"><img src={AssetGallery.linkedinIcon}
                                                                                      alt={'Linkedin'}/></a></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
      </>
  );
};

export default LandingPageView;
