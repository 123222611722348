import React from 'react';
import c from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { getDynamicAssets, isFetchingAssets } from '../../selectors/Asset';
import './FilePicker.scss';
import CheckBox from '../CheckBox/CheckBox';

import noImage from '../../assets/images/no-preview.png';
import DataTable from '../DataTable';
import { fetchDynamicAssets } from '../../actions/Asset';

type Props = {
  picked?: string;
  dynamicId?: string;
  onPick: (assetId: string) => void;
  onUnPick: (assetId: string) => void;
  type: 'images' | 'files' | 'custom'
  fileTypes?: string[];
};

const FilePicker = (props: Props) => {
  const dynamicAssets = useSelector(getDynamicAssets);
  const isLoading = useSelector(isFetchingAssets);
  const { onPick, onUnPick, picked = '', type = '', fileTypes = [], dynamicId = 'primaryId' } = props;
  const innerPicked = picked ? picked.split(',') : '';
  // const [sortBy, setSortBy] = useState('title');
  // const [sortOrder, setSortOrder] = useState('asc');

  // const toggleSort = () => {
  //   setSortBy(sortBy === 'title' ? 'created' : 'title');
  // };

  // const customSort = (a: any, b: any) => {
  //   const multiplier = sortOrder === 'asc' ? 1 : -1;
  //   if (sortBy === 'title') {
  //     return multiplier * a.title.localeCompare(b.title);
  //   } else if (sortBy === 'created') {
  //     // Assuming that assets have a "created" property representing the date created
  //     return multiplier * (new Date(b.created).getTime() - new Date(a.created).getTime());
  //   }
  //   return 0;
  // };

  const assetsFiltered = (dynamicAssets as any)?.filter((a:any) => {
    return (a.id === dynamicId);
  });
  const assets = assetsFiltered[0];
  // Filter assets based on the condition

  const isImg = (mime: string) =>{
    return !(mime.includes('application/pdf') || mime.includes('text/plain'));
  };
    // const toggleSortOrder = () => {
  //   setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  // };

  const config = {
    columns: [ { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'mime', label: 'Mime', mobileFriendly: true },
      { key: 'created', label: 'Time Created', mobileFriendly: true },
      { key: 'updated', label: 'Last Updated', mobileFriendly: true },
    ],
    actions: [],
    hideButton: true,
    pageSizes : [18, 20, 30, 40],
  };

  return (
      <div className="file-picker">
        <div className="row">
            <DataTable paging={assets?.paging || []} data={assets?.data || []} config={config}
                       fetchFunction={fetchDynamicAssets}
                       fetchParams={{ 'id': dynamicId }}
                       isLoading={isLoading}
                       baseSearch={
                           type === 'images'
                             ? { 'mime': 'image' }
                             : type === 'files'
                               ? { 'mime': ['application', 'text'] }
                               : type === 'custom'
                                 ? fileTypes.reduce((searchObj, fileType) => {
                                   return { ...searchObj, 'mime': fileType };
                                 }, {})
                                 : {}
                       }                       isTable={false}
                       lineItemTemplate={(row: any) =>
              <div
                  key={row.id}
                  className="col-xl-2 col-lg-3 col-md-4 col-6"
                  onClick={() => {
                    if (innerPicked.includes(row.id)) {
                      onUnPick(row.id);
                    } else {
                      onPick(row.id);
                    }
                  }}
              >
                <div className="file-picker__img-delete" >
                  <CheckBox
                      type='checkbox'
                      checked={innerPicked?.includes(row.id)}
                  />
                </div>

                                 <>
                                     {(type === 'images' || type === 'custom') && (
                                         <>
                                             <img
                                                 src={`${c.API_ENDPOINTS.ASSET_FILE}/${row.id}`}
                                                 alt={row.title}
                                                 className="file-picker__img-preview"
                                                 onError={(event) => {
                                                   event.currentTarget.src = noImage;
                                                 }}
                                                 loading="lazy"
                                                 style={{ backgroundImage: `url(${noImage})` }}
                                                 onLoad={(event) => {
                                                   event.currentTarget.style.backgroundImage = 'none';
                                                 }}
                                             />
                                             <div className="file-picker__img-title text-start">
                                                 {row.title}.{row.extension}
                                             </div>
                                         </>
                                     )}
                                     {type === 'files' &&
                                         isImg(row.mime) && (
                                             <>
                                                 <img
                                                     src={`${c.API_ENDPOINTS.ASSET_FILE}/${row.id}`}
                                                     alt={row.id}
                                                     className="file-picker__img-preview img-thumbnail"
                                                     onError={(event) => {
                                                       event.currentTarget.src = noImage;
                                                     }}
                                                     loading="lazy"
                                                     style={{ backgroundImage: `url(${noImage})` }}
                                                     onLoad={(event) => {
                                                       event.currentTarget.style.backgroundImage = 'none';
                                                     }}
                                                 />
                                                 <div className="file-picker__img-title">
                                                     {row.title}.{row.extension}
                                                 </div>
                                             </>

                                     )}
                                     {type === 'files' &&
                                         !isImg(row.mime) && (
                                             <>
                                                 <div style={{ display: 'inline-block' }}>
                                                     <iframe
                                                         src={`${c.API_ENDPOINTS.ASSET_FILE}/${row.id}`}
                                                         className="file-picker__img-preview img-thumbnail"
                                                         onError={(event) => {
                                                           event.currentTarget.src = noImage;
                                                         }}
                                                         loading="lazy"
                                                         style={{ backgroundImage: `url(${noImage})` }}
                                                         onLoad={(event) => {
                                                           event.currentTarget.style.backgroundImage = 'none';
                                                         }}
                                                     />
                                                 </div>
                                                 <div className="file-picker__img-title">
                                                     {row.title}.{row.extension}
                                                 </div>
                                             </>
                                     )}
                                 </>
              </div>
                       }
            />
        </div>
      </div>
  );
};

export default FilePicker;
