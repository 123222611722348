import axios from 'axios';

import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function getMarketplace(id: string,  groupShortcode?: string,
  clientShortcode?: string) {
  const baseUrl = `${c.API_ENDPOINTS.MARKETPLACE}`;

  // Conditionally append "-shortcode" to the URL if both groupShortcode and clientShortcode are provided
  const url = groupShortcode && clientShortcode
    ? `${baseUrl}-shortcode/${id}`
    : `${baseUrl}/${id}`;

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        id,
        groupShortcode,
        clientShortcode,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getMarketplaces(dataTableParams?: DataTableParamsModel) {
  return (
    axios({
      url: c.API_ENDPOINTS.MARKETPLACE,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params:{
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getMarketplacesPlusPublic(
  dataTableParams?: DataTableParamsModel,
) {

  const baseUrl = `${c.API_ENDPOINTS.MARKETPLACES_PUBLIC}`;
  const fetchParams = dataTableParams?.fetchParams;

  const url = fetchParams?.groupShortcode && fetchParams?.clientShortcode
    ? `${baseUrl}-shortcode`
    : baseUrl;

  return (
    axios({
      url,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
      params: {
        dataTableParams,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postMarketplace(
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  asset: string,
  active: boolean,
  visibility: string,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.MARKETPLACE,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        asset,
        active,
        visibility,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putMarketplace(
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  asset: string,
  active: boolean,
  visibility: string,
) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.MARKETPLACE}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        asset,
        active,
        visibility,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function archiveMarketplace(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.MARKETPLACE}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

