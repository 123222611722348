import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_AUCTIONS_REQUEST,
  FETCH_AUCTIONS_RESPONSE,
  FETCH_AUCTIONS_FAILURE,
  FETCH_AUCTION_REQUEST,
  FETCH_AUCTION_RESPONSE,
  FETCH_AUCTION_FAILURE,
  POST_AUCTION_FAILURE,
  POST_AUCTION_REQUEST,
  POST_AUCTION_RESPONSE,
  AuctionActionTypes, AuctionDataModel, Auction,
} from '../types/Auction';

import { RootState } from '../store/configureStore';

import { archiveAuction, getAuction, getAuctions, getAuctionsPlusPublic, postAuction, putAuction } from '../api/Auction';
import { formatUrl } from '../helpers/functions';
import c from '../helpers/constants';
import { DataTableParamsModel } from '../types/Common';

export function fetchAuctionsRequest(): AuctionActionTypes {
  return {
    type: FETCH_AUCTIONS_REQUEST,
    payload: null,
  };
}

export function fetchAuctionsResponse(
  auctions: AuctionDataModel,
): AuctionActionTypes {
  return {
    type: FETCH_AUCTIONS_RESPONSE,
    payload: auctions,
  };
}

export function fetchAuctionsFailure(): AuctionActionTypes {
  return {
    type: FETCH_AUCTIONS_FAILURE,
    payload: null,
  };
}

export function fetchAuctionRequest(): AuctionActionTypes {
  return {
    type: FETCH_AUCTION_REQUEST,
    payload: null,
  };
}

export function fetchAuctionResponse(
  auction: Auction,
): AuctionActionTypes {
  return {
    type: FETCH_AUCTION_RESPONSE,
    payload: auction,
  };
}

export function fetchAuctionFailure(): AuctionActionTypes {
  return {
    type: FETCH_AUCTION_FAILURE,
    payload: null,
  };
}


export function postAuctionRequest(): AuctionActionTypes {
  return {
    type: POST_AUCTION_REQUEST,
    payload: null,
  };
}

export function postAuctionResponse(): AuctionActionTypes {
  return {
    type: POST_AUCTION_RESPONSE,
    payload: null,
  };
}

export function postAuctionFailure(error: string, validationErrors: any): AuctionActionTypes {
  return {
    type: POST_AUCTION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveAuction = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAuctionRequest());
    const asyncResp: any = await getAuction(id);
    if (asyncResp?.success) {
      await dispatch(fetchAuctionResponse(asyncResp.data));
    } else {
      await dispatch(fetchAuctionFailure());
    }
  };

export const fetchAuctions = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAuctionsRequest());
    const asyncResp: any = await getAuctions(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchAuctionsResponse(asyncResp));
    } else {
      await dispatch(fetchAuctionsFailure());
    }
  };

export const fetchAuctionsPlusPublic = (datatableParams?: DataTableParamsModel):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAuctionsRequest());
    const asyncResp: any = await getAuctionsPlusPublic(datatableParams);
    if (asyncResp?.success) {
      await dispatch(fetchAuctionsResponse(asyncResp));
    } else {
      await dispatch(fetchAuctionsFailure());
    }
  };

export const storeAuction = async (id: string) => {
  try {
    await archiveAuction(id);
  } catch (error) {
    throw error;
  }
};

export const createAuction = (
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  assetId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
  visibility: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAuctionRequest());
    const asyncResp: any = await postAuction(groupId, clientId, title, description, assetId, startingBid, startDateTime, endDateTime, bidIncrement, visibility);
    if (asyncResp?.success) {
      await dispatch(postAuctionResponse());
    } else {
      await dispatch(postAuctionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const createAuctionFromSteps = (
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  assetId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
  visibility: string,
  history: any,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAuctionRequest());
    const asyncResp: any = await postAuction(groupId, clientId, title, description, assetId, startingBid, startDateTime, endDateTime, bidIncrement, visibility);
    if (asyncResp?.success) {
      await dispatch(postAuctionResponse());
      await dispatch(fetchAuctions());
      history.push(formatUrl(c.APP_ROUTES.AUCTION_CREATE_STEP_TWO, asyncResp?.data.id));
    } else {
      await dispatch(postAuctionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateAuction = (
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  assetId: string,
  startingBid: string,
  startDateTime: string,
  endDateTime: string,
  bidIncrement: string,
  visibility: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAuctionRequest());
    const asyncResp: any = await putAuction(id, groupId, clientId,  title, description, assetId, startingBid, startDateTime, endDateTime, bidIncrement, visibility);
    if (asyncResp?.success) {
      await dispatch(postAuctionResponse());
    } else {
      await dispatch(postAuctionFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


