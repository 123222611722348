import React, { useEffect, useState } from 'react';
import View from '../../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser, getUsers } from '../../selectors/User';
import { fetchActiveUser } from '../../actions/User'; // Import the updateUser action
import { getUserToken } from '../../helpers/functions';
import c from '../../helpers/constants';
import './UserProfile.scss';
import avatarPlaceholder from './avatar-placeholder.png';
import plants from './laptop-plants.svg';
import assetGallery from '../../components/AssetGallery';
import Tabs from '../../components/Tabs';
import AccountInformation from './profile-components/AccountInformation';
import ChangePassword from './profile-components/ChangePassword';
import MyAuctions from './profile-components/MyAuctions';
import MyBids from './profile-components/MyBids';
import FavouriteTags from './profile-components/FavouriteTags';
import ProfilePicture from './profile-components/ProfilePicture';

const UserProfile = () => {
  const activeUser = useSelector(getActiveUser);
  const loadUser = useSelector(getUsers);
  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState(activeUser?.avatar || '');


  useEffect(() => {
    dispatch(fetchActiveUser(getUserToken()));
  }, [dispatch, loadUser]);

  useEffect(() => {
    if (activeUser){
      setAvatar(activeUser?.avatar);
    }
  }, [activeUser, loadUser]);


  const tabs = [
    {
      label: 'Account Information',
      content: <AccountInformation />,
    },
    {
      label: 'Change Password',
      content: <ChangePassword />,
    },
    {
      label: 'My Auctions',
      content: <MyAuctions />,
    },
    {
      label: 'My Bids',
      content: <MyBids />,
    },
    {
      label: 'Favourite Tags',
      content: <FavouriteTags />,
    },
    {
      label: 'Profile Picture',
      content: <ProfilePicture />,
    },
  ];

  return (
        <div style={{ height: '100%' }}>
            <View title={'USER PROFILE'}>
              <div className='user-profile__frame-circles'>
                <img src={assetGallery.bgCirclesImg}  alt='bg-circles'/>
              </div>
              <div className="user-profile__content" style={{ height: '100%' }}>
                  <div className="user-profile__position">
                    {avatar ? (
                      <div className="user-profile__frame-avatar">
                        <img
                          className={'user-profile__frame-avatar--img'}
                          src={`${c.API_ENDPOINTS.ASSET_FILE}/${avatar}`}
                          alt="User Avatar"
                        />
                      </div>
                    ) : (
                      <div className="user-profile__frame-avatar">
                        <img
                          src={avatarPlaceholder}
                          alt="User Avatar"
                          className={'user-profile__frame-avatar--img'}
                        />
                      </div>
                    )}
                  </div>
                <Tabs tabs={tabs} activeTabIndex={0} />
                </div>
              <div className="background-image">
                <img
                  src={plants}
                  alt="Background Image"
                />
              </div>
            </View>
        </div>
  );
};

export default UserProfile;
