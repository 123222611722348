import React from 'react';
import View from '../../../components/View/View';
import c from '../../../helpers/constants';
import { formatUrl } from '../../../helpers/functions';
import { useHistory } from 'react-router-dom';
import Stepper from '../../../components/Stepper';
import { useEditMode } from '../../../hooks/EditModeHook';
import Button from '../../../components/Button';
import TickMark from '../../../assets/images/svgs/tick_mark.svg';
import './AuctionWizard.scss';


const StepFour = () => {
  const history = useHistory();

  const steps = [
    'Provide Auction Details',
    'Select Items For Auction',
    'Overview',
    'Success',
  ];

  const [editMode, activeId ] = useEditMode(c.APP_ROUTES.AUCTION_CREATE_STEP_FOUR);

  const onNavigate = () => {
    if (editMode) {
      history.push(formatUrl(c.APP_ROUTES.VIEW_AUCTION, activeId));
    }
  };

  return (
      <View>
          <div className="container-fluid">
              <div className="row justify-content-center align-items-center">
                  <div className="col-8 mb-4">
                      <Stepper steps={steps} activeStep={3} hideMobileLabels={true}/>
                  </div>
              </div>

              <div className="row p-4 justify-content-center align-items-center">
                  <div className="row  justify-content-center align-items-center auction-wizard__box">
                      <div className="row justify-content-center align-items-center  p-md-5 p-sm-0">
                      <div className='row mt-5 justify-content-center align-items-center'>
                          <div className='col-12 text-center'><img className="auction-wizard__circle" src={TickMark} alt='checkmark'/></div>
                      </div>
                      <div className='row my-1'>
                          <div className='col-12  auction-wizard__success-title text-center'>Auction Ready: Your Auction and Details Confirmed!</div>
                      </div>

                      <div className='row my-1'>
                          <div className='col-12  text-center'>Well done! You’ve meticulously filled out the details for your auction. Click Go to Auction to witness the live preview of your auction, showcasing your carefully chosen items and auction specifics.</div>
                      </div>

                      <div className='row my-1'>
                          <div className='col-12  text-center'>
                              <Button onClick={() => onNavigate()} title={'Go To Auction'} />
                          </div>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
      </View>

  );
};

export default StepFour;

